import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="header">
      <div className="container header__container">
        <Link to="/">
          <img
            className="header__logo"
            src="images/svg/turgoil-logo.svg"
            alt="Turgoil logo"
          />
        </Link>
        <h5 className="header__links">
          <Link to="/signin" className="header__login">
            Logi sisse
          </Link>
        </h5>
      </div>
    </header>
  );
}
