import React from "react";
import classNames from "classnames";

type ConversationTitleProps = {
  className?: string;
  children: React.ReactNode;
};

function ConversationTitle(props: ConversationTitleProps) {
  const { children, className } = props;
  return (
    <div className={classNames(className, "conversation__conversation-title")}>
      {children}
    </div>
  );
}

type ConversationContentProps = {
  className?: string;
  children: React.ReactNode;
};

function ConversationContent(props: ConversationContentProps) {
  const { children, className } = props;
  return (
    <div
      className={classNames(className, "conversation__conversation-content")}
    >
      {children}
    </div>
  );
}

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
};

class Conversation extends React.PureComponent<Props> {
  static Title = ConversationTitle;

  static Content = ConversationContent;

  render() {
    const { children, className, onClick } = this.props;

    return (
      <div
        onClick={onClick}
        className={classNames(className, "conversation__conversation")}
      >
        {children}
      </div>
    );
  }
}

export default Conversation;
