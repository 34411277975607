import React from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "./sub/Toast";

export const ToastType = {
  Error: "Error",
  Warning: "Warning",
  Success: "Success",
  Info: "Info",
};

type Props = {
  toasts: any[];
};

class Toasts extends React.PureComponent<Props> {
  private el = document.createElement("div");

  componentDidMount() {
    const { body } = document;
    body.appendChild(this.el);
  }

  componentWillUnmount() {
    const { body } = document;
    body.removeChild(this.el);
  }

  getToastStyles = (toast) => {
    switch (toast.type) {
      case ToastType.Error:
        return "danger";
      case ToastType.Warning:
        return "warning";
      case ToastType.Success:
        return "success";
      default:
        return "";
    }
  };

  render() {
    const { toasts } = this.props;

    if (!this.el) {
      return null;
    }

    return ReactDOM.createPortal(
      <div aria-live="polite" aria-atomic="true">
        <ToastContainer
          position="bottom-end"
          className="p-3 d-flex flex-column position-fixed"
        >
          {toasts.map((toast, index) => (
            <Toast
              bg={this.getToastStyles(toast)}
              key={index}
              text={toast.text}
              title={toast.title}
            />
          ))}
        </ToastContainer>
      </div>,
      this.el,
    );
  }
}

function mapStateToProps(state) {
  return {
    toasts: state.toastsReducer.toasts,
  };
}

export default connect(mapStateToProps)(Toasts);
