import React, { useEffect, useCallback, useRef, useState } from "react";
import { Button, Overlay, Popover, Form } from "react-bootstrap";
import { stampOilRequestAsChoosingWinner } from "../../../services/turgoil-api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

type Props = {
  onSuccess: () => void;
  oilRequest: any;
};

const StampOilRequestAsChoosingWinner: React.FC<Props> = ({
  onSuccess,
  oilRequest,
}): React.ReactElement => {
  const buttonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const { id } = useParams();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const user = useSelector((state: RootState) => state.userReducer.user);

  const stamp = () => {
    setLoading(true);
    stampOilRequestAsChoosingWinner(id, { comment })
      .then(() => {
        setComment("");
        setLoading(false);
        setPopoverOpen(false);
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const togglePopover = useCallback(() => {
    setPopoverOpen(!popoverOpen);
  }, [popoverOpen]);

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  if (
    !oilRequest ||
    oilRequest.status !== "Active" ||
    oilRequest.user.id !== user.id
  ) {
    return null;
  }

  return (
    <>
      <Button
        id="stampOilRequestAsChoosingWinner"
        type="button"
        onClick={togglePopover}
        ref={buttonRef}
        variant="warning"
      >
        Lõpeta hange
      </Button>

      <Overlay
        show={popoverOpen}
        onHide={togglePopover}
        target={buttonRef}
        rootClose
      >
        <Popover id="popover-basic">
          <Popover.Header as="h3">
            Kas olete kindel, et soovite hanke lõpetada ennetähtaegselt?
          </Popover.Header>
          <Popover.Body>
            <p>
              Juhul kui Teile on tehtud pakkumisi, siis hange liigub olekusse
              "Võitja valimine". Kui ei ole pakkumisi, siis liigub olekusse
              "Lõpetatud".
            </p>
            {/* <Form.Group className="mb-2"> */}
            {/*   <Form.Label className="mb-0 d-block"> */}
            {/*     Kommentaar */}
            {/*   </Form.Label> */}
            {/*   <Form.Control */}
            {/*     as="textarea" */}
            {/*     name="comment" */}
            {/*     placeholder="" */}
            {/*     rows={3} */}
            {/*     onChange={handleCommentChange} */}
            {/*     isInvalid={false} */}
            {/*     value={comment} */}
            {/*   /> */}

            {/*   <Form.Control.Feedback type="invalid"> */}
            {/*     "" */}
            {/*   </Form.Control.Feedback> */}
            {/* </Form.Group> */}

            <hr />

            <Button type="button" variant="light" onClick={togglePopover}>
              Loobu
            </Button>
            <Button
              type="button"
              variant="warning"
              className="float-end"
              onClick={stamp}
            >
              Jah
            </Button>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default StampOilRequestAsChoosingWinner;
