import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Table } from "react-bootstrap";
import { getUsers } from "../services/turgoil-api";
import { getRoleDisplayName } from "../utils/user";

const AdminPage: React.FC = (): React.ReactElement => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers({}).then((response) => {
      setUsers(response);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Turgoil - Adminstratsioon</title>
      </Helmet>

      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Kontakt E-posti aadress</th>
            <th>Kontakt telefon</th>
            <th>E-posti aadress</th>
            <th>Nimi</th>
            <th>Telefon</th>
            <th>Ettevõtted</th>
            <th>Roll</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr>
              <td>{u.id}</td>
              <td>{u.contactEmail}</td>
              <td>{u.contactPhone}</td>
              <td>{u.email}</td>
              <td>{u.name}</td>
              <td>{u.phone}</td>
              <td>
                {u.companies?.map((c, i) => (
                  <span style={{ color: c.confirmed === 1 ? "green" : "red" }}>
                    {c.name}
                    {i !== u.companies.length - 1 && ', '}
                  </span>
                ))}
              </td>
              <td>{getRoleDisplayName(u.role)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AdminPage;
