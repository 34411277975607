import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.PureComponent {
  render() {
    return (
      <footer className="footer">
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <h5>Uuri lähemalt</h5>
            <ul>
              <li>
                <Link to="/">Avaleht</Link>
              </li>
              <li>
                <Link to="/instructions">Kasutusjuhend</Link>
              </li>
              <li>
                <Link to="/frequently-asked-questions">Korduvad küsimused</Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h5>Dokumendid</h5>
            <ul>
              <li>
                <Link to="/terms-and-conditions">Kasutustingimused</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privaatsustingimused</Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h5>Meedia</h5>
            <ul>
              <li>
                <Link to="/advertisement">Reklaam</Link>
              </li>
            </ul>
          </div>
          <div className="footer__contact col-sm-3">
            <h5>Kontakt</h5>
            <ul>
              <li>
                <a href="mailto:info@turgoil.com">info@turgoil.com</a>
              </li>
              <li>TI-haldus OÜ</li>
              <li>Registrikood: 14121440</li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
