import React from "react";

class SignUpComplete extends React.PureComponent {
  render() {
    return (
      <div className="register-complete">
        <h4>Registreerimine õnnestus!</h4>
        <p>
          Teile on saadetud kasutaja aktiveerimiseks juhised e-posti aadressile.
          Kui olete oma kasutaja juba aktiveerinud, siis logige sisse.
        </p>
        <div className="register-complete__picture" />
      </div>
    );
  }
}

export default SignUpComplete;
