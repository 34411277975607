import { ThunkDispatch } from "redux-thunk";
import * as api from "../services/turgoil-api";
import { RootState } from "./store";

const USER_LOADING = "user/fetching";
const USER_SUCCESS = "user/success";
const USER_LOGOUT = "user/logout";

export type UserState = {
  loading: boolean;
  user: any;
};

const initialState: UserState = {
  loading: false,
  user: null,
};

export default function reducer(
  state = initialState,
  action: UserSuccess | UserLoading | UserLogout,
) {
  switch (action.type) {
    case USER_LOGOUT:
      return {
        user: null,
        loading: false,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_SUCCESS:
      return {
        user: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
type UserLoading = {
  type: typeof USER_LOADING;
};

type UserSuccess = {
  type: typeof USER_SUCCESS;
  payload: any;
};

type UserLogout = {
  type: typeof USER_LOGOUT;
};

export function loading(): UserLoading {
  return { type: USER_LOADING };
}

export function logout(): UserLogout {
  return {
    type: USER_LOGOUT,
  };
}

export function success(user: any): UserSuccess {
  return {
    type: USER_SUCCESS,
    payload: user,
  };
}

export function getUser(userId: string) {
  return async (
    dispatch: ThunkDispatch<RootState, null, UserLoading | UserSuccess>,
  ) => {
    dispatch(loading());
    return api.getUser(userId).then((res) => {
      dispatch(success(res.data));
    });
  };
}
