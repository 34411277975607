import React, { PureComponent } from "react";
import Big from "big.js";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";

import { numberWithSpaces } from "../../../utils/format";
import * as toastActions from "../../../state/toasts";
import { ToastType } from "../../../common/Toasts";
import {
  createOilRequestBid,
  editOilRequestBid,
} from "../../../services/turgoil-api";
import BidForm, { Bid } from "./BidForm";
import { OilRequestOilType } from "../../../utils/config";

function calculateTotalPrice(bids, oils) {
  let total = new Big(0);

  oils.forEach((oil) => {
    const oilBid = bids.find((oBid) => oil.id === oBid.oilId);
    if (oilBid.price) {
      total = total.plus(new Big(oilBid.price || 0).times(oil.amount));
    }
  });

  return total.toFixed(2);
}

const Mode = {
  Edit: "Edit",
  View: "View",
};

function getMode(user, oilRequestBids) {
  const bid = oilRequestBids.find(
    (oilRequestBid) => oilRequestBid.companyId === user.bidderInfo.companyId,
  );
  if (bid) {
    return Mode.Edit;
  }

  return Mode.View;
}

type State = {
  price: string;
  comment: string;
  errors: Record<string, Partial<Record<keyof Omit<Bid, "oilId">, string>>>;
  bids: Bid[];
};

function getInitialState(user, oils, bids): State {
  if (user.role === "Bidder") {
    const companyBid = bids.find(
      (b) => b.companyId === user.bidderInfo.companyId,
    );
    if (companyBid) {
      return {
        price: companyBid.price,
        bids: companyBid.oilBids,
        comment: companyBid.comment,
        errors: {},
      };
    }
  }

  return {
    price: "0",
    comment: "",
    errors: {},
    bids: oils.map((o) => ({
      price: "",
      oilId: o.id,
      marking: o.marking,
    })),
  };
}

type Props = {
  user: any;
  oilRequest: any;
  oilRequestBids: any[];
  updateOilRequest: () => void;
  showToast: typeof toastActions.showToast;
};

function countDecimals(number: string): number {
  return number.split(".")[1]?.length || 0;
}

export function getDisplayValueWithTax(
  num: string,
  precision?: number,
): string {
  const valueWithTax = new Big(num || 0).times(new Big("1.22"));
  return countDecimals(valueWithTax.toFixed()) > precision
    ? valueWithTax.toFixed(precision)
    : valueWithTax.toFixed();
}

class CreateOrEditBid extends PureComponent<Props, State> {
  private mode: string;

  constructor(props: Readonly<Props>) {
    super(props);

    this.mode = getMode(props.user, props.oilRequestBids);

    this.state = getInitialState(
      props.user,
      props.oilRequest.oils,
      props.oilRequestBids,
    );
  }

  private validate = () => {
    const { bids } = this.state;
    const bidErrors = {};

    for (const bid of bids) {
      bidErrors[bid.oilId] = {};
      if (!bid.price) {
        bidErrors[bid.oilId].price = "See väli on kohustuslik.";
      }
      if (bid.cloudPoint && parseInt(bid.cloudPoint, 10) > 100) {
        bidErrors[bid.oilId].cloudPoint = "Väärtus peab olema väiksem 100";
      }
      if (bid.cloudPoint && parseInt(bid.cloudPoint, 10) < -100) {
        bidErrors[bid.oilId].cloudPoint = "Väärtus peab olema suurem -100";
      }
      if (
        bid.coldFilterPlugPoint &&
        parseInt(bid.coldFilterPlugPoint, 10) > 100
      ) {
        bidErrors[bid.oilId].coldFilterPlugPoint =
          "Väärtus peab olema väiksem 100";
      }
      if (
        bid.coldFilterPlugPoint &&
        parseInt(bid.coldFilterPlugPoint, 10) < -100
      ) {
        bidErrors[bid.oilId].coldFilterPlugPoint =
          "Väärtus peab olema suurem -100";
      }
    }

    this.setState({ errors: bidErrors });

    return !Object.values(bidErrors).some((e) => Object.keys(e).length !== 0);
  };

  private isCloudPoint = (dirtyBid: Bid): boolean => {
    const { oilRequest } = this.props;
    const oil = oilRequest.oils.find((o) => o.id === dirtyBid.oilId);
    return (
      ([
        String(OilRequestOilType.DieselSpecial),
        String(OilRequestOilType.Diesel),
      ].includes(oil.oilType) &&
        ["A0", "A1", "A2", "HVO"].includes(dirtyBid.marking)) ||
      oil.oilType === OilRequestOilType.HydrotreatedVegetableOil
    );
  };

  private isColdFilterPlugPoint = (dirtyBid: Bid): boolean => {
    const { oilRequest } = this.props;
    const oil = oilRequest.oils.find((o) => o.id === dirtyBid.oilId);
    return (
      ([
        String(OilRequestOilType.DieselSpecial),
        String(OilRequestOilType.Diesel),
      ].includes(oil.oilType) &&
        ["E", "F", "A0", "A1", "A2", "HVO"].includes(dirtyBid.marking)) ||
      oil.oilType === OilRequestOilType.HydrotreatedVegetableOil
    );
  };

  private createOrEditBid = (event: React.MouseEvent) => {
    const { oilRequest, oilRequestBids, showToast, user } = this.props;
    const { price, comment, bids } = this.state;
    event.preventDefault();

    if (!this.validate()) {
      return;
    }

    const request: {
      price: string;
      comment: string;
      oilBids: Bid[];
    } = {
      price,
      comment,
      oilBids: bids.map((b) => ({
        cloudPoint: this.isCloudPoint(b)
          ? b.cloudPoint || undefined
          : undefined,
        coldFilterPlugPoint: this.isColdFilterPlugPoint(b)
          ? b.coldFilterPlugPoint || undefined
          : undefined,
        price: b.price || undefined,
        oilId: b.oilId || undefined,
        marking: b.marking || undefined,
      })),
    };

    if (this.mode !== Mode.Edit) {
      createOilRequestBid(oilRequest.id, request)
        .then(() => {
          this.props.updateOilRequest();
          showToast({
            title: "Päring õnnestus.",
            text: "Pakkumine edukalt loodud!",
            type: ToastType.Success,
          });
        })
        .catch((err) => {
          showToast({
            title: "Päring ebaõnnestus.",
            text: err.message,
            type: ToastType.Error,
          });
        });

      return;
    }

    const bidId = oilRequestBids.find(
      (oilRequestBid) => oilRequestBid.companyId === user.bidderInfo.companyId,
    ).id;

    editOilRequestBid(oilRequest.id, bidId, request)
      .then(() => {
        this.props.updateOilRequest();
        showToast({
          title: "Päring õnnestus.",
          text: "Pakkumine edukalt muudetud!",
          type: ToastType.Success,
        });
      })
      .catch((err) => {
        showToast({
          title: "Päring ebaõnnestus.",
          text: err.message,
          type: ToastType.Error,
        });
      });
  };

  private handleBidChange = (bid: Bid) => {
    const { bids } = this.state;
    const { oilRequest } = this.props;
    const index = bids.findIndex((oilBid) => oilBid.oilId === bid.oilId);
    const nextBids = [...bids];
    nextBids[index] = bid;

    const totalPrice = calculateTotalPrice(nextBids, oilRequest.oils);

    this.setState((prevState) => ({
      price: totalPrice,
      bids: nextBids,
      errors: {
        ...prevState.errors,
        [bid.oilId]: {},
      },
    }));
  };

  private handleChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  render() {
    const { bids, price, errors } = this.state;
    const { oilRequest } = this.props;

    return (
      <section>
        <h5 className="oil-request-page__heading">
          {this.mode === Mode.Edit ? "Muuda pakkumist" : "Tee pakkumine"}
        </h5>
        {/* <Form.Text muted> */}
        {/* 	Sisesta vedelkütuse liitri hind koos käibemaksuga. */}
        {/* </Form.Text> */}
        <hr />

        {/* <div className="row"> */}
        {/*   <div className="col-sm-6 form-label d-none d-sm-block"> */}
        {/*     Hind ilma käibemaksuta */}
        {/*   </div> */}
        {/*   <div className="col-sm-6 form-label d-none d-sm-block"> */}
        {/*     Hind koos käibemaksuga */}
        {/*   </div> */}
        {/* </div> */}
        <div className="row">
          {oilRequest.oils.map((oil) => (
            <BidForm
              key={oil.oilType}
              oil={oil}
              bid={bids.find((b) => b.oilId === oil.id)}
              onChange={this.handleBidChange}
              errors={errors[oil.id]}
            />
          ))}
        </div>

        <div className="row">
          <div className="col-sm-12">
            <Form.Group className="mb-2">
              <Form.Label className="create-request__description-label">
                Kommentaar
              </Form.Label>
              <textarea
                onChange={this.handleChange}
                name="comment"
                value={this.state.comment}
                className="form-control"
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <Form.Group className="mb-2">
              <Form.Label className="create-request__description-label">
                Pakkumine ilma käibemaksuta
              </Form.Label>
              <Form.Control
                value={numberWithSpaces(new Big(price || 0).toFixed(2)) + " €"}
                plaintext
              />
            </Form.Group>
          </div>
          <div className="col-sm-6">
            <Form.Group className="mb-2">
              <Form.Label className="create-request__description-label">
                Pakkumine koos käibemaksuga
              </Form.Label>
              <Form.Control
                value={numberWithSpaces(getDisplayValueWithTax(price)) + " €"}
                plaintext
              />
            </Form.Group>
          </div>
        </div>
        <button
          className="btn btn-warning"
          type="submit"
          onClick={this.createOrEditBid}
        >
          {this.mode === Mode.Edit ? "Salvesta" : "Tee pakkumine"}
        </button>
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(null, mapDispatchToProps)(CreateOrEditBid);
