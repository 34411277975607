import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import Oils from "../../../common/Oils";
import * as Service from "../helpers";
import * as date from "../../../utils/date";

type Props = {
  request: any;
  requestTimeLeft: string;
};

class Request extends PureComponent<Props> {
  render() {
    const { request, requestTimeLeft } = this.props;
    const color = Service.getOilRequestStatusColor(request.status);

    return (
      <div className="request">
        <div className="request__container">
          <div className="request__id">
            <span className="me-2">
              <Link to={"/oil-requests/" + request.id} className="link-gray">
                {Service.getOilRequestTitleV2(request)}
              </Link>
            </span>
            <span className="">
              <Badge
                pill
                bg={Service.getOilRequestStatusColor2(request.status)}
              >
                {Service.getOilRequestStatusName(request.status)}
              </Badge>
            </span>
            {request.isBidMade && (
              <small className="request__bid">Pakkumine tehtud</small>
            )}
          </div>
          <div className="request__detail">
            {request.requester && (
              <>
                <div className="request__detail-item request__detail-item-left">
                  Korraldaja:
                </div>
                <div className="request__detail-item">{request.requester}</div>
              </>
            )}
            <div className="request__detail-item request__detail-item-left">
              Lõppkuupäev:
            </div>
            <div className="request__detail-item">
              {date.getDateTime(request.endDatetime)}
            </div>
            <div className="request__detail-item request__detail-item-left">
              Tarnetähtaeg:
            </div>
            <div className="request__detail-item">
              {date.getDate(request.transportDate)}
            </div>
            <div className="request__detail-item request__detail-item-left">
              Maksetähtaeg:
            </div>
            <div className="request__detail-item">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {date.getDate(request.paymentDate)}
                  </Tooltip>
                }
              >
                <span>
                  {date.getDateDayDifference(request.paymentDate, request.transportDate)}
                </span>
              </OverlayTrigger>
            </div>
            <div className="request__detail-item request__detail-item-left">
              Transport:
            </div>
            <div className="request__detail-item">
              {Service.getOilRequestTransportName(request.transportCode)}
            </div>
            <div className="request__detail-item request__detail-item-left">
              Asukoht:
            </div>
            <div className="request__detail-item">{request.address}</div>
          </div>
        </div>
        <div className="request__oil">
          {/* <div className="request__oil-title">Kütused:</div> */}
          <Oils oils={request.oils} />
        </div>
        <div className="request__info">
          {requestTimeLeft && (
            <div className="request__time">
              Aega lõpuni
              <div className="text-center">
                <strong>{requestTimeLeft}</strong>
              </div>
            </div>
          )}
          <div className="request__date">
            <div>Lisatud: {date.getDate(request.regDatetime)}</div>
            <div>{date.getTime(request.regDatetime)}</div>
          </div>
          <Link
            to={`/oil-requests/${request.id}`}
            className="request__look-button btn btn-light"
          >
            Vaata lähemalt
          </Link>
        </div>
        <div className="request__mobile">
          <Link to={`/oil-requests/${request.id}`}>
            <a
              href={`/oil-requests/${request.id}`}
              className="btn request__look-button--mobile"
            >
              Vaata lähemalt
            </a>
          </Link>
        </div>
      </div>
    );
  }
}

export default Request;
