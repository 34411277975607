import React, { PureComponent } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  useAccordionButton,
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";

import * as userActions from "../../../state/user";
import * as toastActions from "../../../state/toasts";
import { editUser } from "../../../services/turgoil-api";
import { ToastType } from "../../../common/Toasts";
import Loader from "../../../common/Loader";
import { validateEmail } from "../../../utils/regex";

moment.locale("et");

function getDateTime(dateTimeString) {
  const now = moment();
  const demandDateTime = moment(dateTimeString);
  if (now.year() !== demandDateTime.year()) {
    return demandDateTime.format("DD.MM.YYYY HH:mm");
  }
  return demandDateTime.format("D. MMMM HH:mm");
}

function CustomAccordionToggle({ children }) {
  const decoratedOnClick = useAccordionButton("");

  return (
    <Button type="button" variant="light" onClick={decoratedOnClick}>
      {children}
    </Button>
  );
}

type Props = {
  user: any;
  setUser: (u: any) => void;
  showToast: typeof toastActions.showToast;
};

type User = {
  forename: string;
  surname: string;
  contactPhone: string;
  contactEmail: string;
};

type State = {
  loading: boolean;
  editing: boolean;
  errors: Record<string, string>;
  user: User;
};

class Profile extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editing: false,
      errors: {},
      user: {
        forename: "",
        surname: "",
        contactPhone: "",
        contactEmail: "",
      },
    };
  }

  validate = () => {
    const { forename, surname, contactEmail, contactPhone } = this.state.user;
    const errors: Record<string, string> = {};

    if (!forename) {
      errors.forenameError = "Eesnimi on kohustuslik";
    }
    if (!surname) {
      errors.surnameError = "Perenimi on kohustuslik";
    }
    if (contactEmail.length && !validateEmail(contactEmail)) {
      errors.contactEmailError =
        "See ei ole korrektne e-posti aadress. Korrektne on näiteks info@turgoil.com";
    }
    if (contactPhone.length > 20) {
      errors.contactPhoneError =
        "Telefoni numbri maksimaalne pikkus on 20 tähemärki.";
    }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { user, showToast } = this.props;

    if (!this.validate()) {
      return;
    }

    this.setState({ loading: true });
    editUser(user.id, this.state.user)
      .then((userRes) => {
        this.props.setUser(userRes);
        showToast({
          title: "Päring õnnestus",
          text: "Kasutaja andmete muutmine õnnestus.",
          type: ToastType.Success,
        });

        this.setState({
          loading: false,
          editing: false,
          user: {
            forename: "",
            surname: "",
            contactPhone: "",
            contactEmail: "",
          },
        });
      })
      .catch((err) => {
        showToast({
          title: "Päring ebaõnnestus.",
          text: err.message,
          type: ToastType.Error,
        });

        this.setState({
          loading: false,
          errors: {
            generalError: err.message,
          },
        });
      });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [`${name}Error`]: "",
        generalError: "",
      },
    }));
  };

  closeEditingView = () => {
    this.setState({ editing: false });
  };

  openEditingView = () => {
    const { user } = this.props;
    this.setState({
      editing: true,
      user: {
        contactEmail: user.contactEmail || "",
        contactPhone: user.contactPhone || "",
        forename: user.forename || "",
        surname: user.surname || "",
      },
    });
  };

  render() {
    const { user } = this.props;
    const { errors, editing, loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className="settings__content settings__profile">
        {!editing && (
          <>
            <div className="settings__profile-grid">
              <div className="settings__profile-title">Eesnimi</div>
              <div>{user.forename}</div>
              <div className="settings__profile-title">Perenimi</div>
              <div>{user.surname}</div>
              <div className="settings__profile-title">E-posti aadress</div>
              <div>{user.email}</div>
              <div className="settings__profile-title">
                Registreerimise kuupäev
              </div>
              <div>{getDateTime(user.regDatetime)}</div>
              <div className="settings__profile-title">
                Andmete muutmise kuupäev
              </div>
              <div>{getDateTime(user.modDatetime)}</div>
              <div className="settings__profile-title">
                Kontakt e-posti aadress
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Alternatiivne e-posti aadress mis kuvatakse hankijale või
                      pakkujale kui valitakse võitja. Lisaks hakkab see olema
                      e-posti aadress kuhu saadetakse teavitusi.
                    </Tooltip>
                  }
                >
                  <img
                    id="contactEmail"
                    className="create-request__help"
                    src="/images/svg/help.svg"
                    alt="info"
                  />
                </OverlayTrigger>
              </div>

              <div className="settings__profile-text">
                {user.contactEmail ? user.contactEmail : "–"}
              </div>
              <div className="settings__profile-title">
                Kontakt telefoni number
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Telefoni number, mis kuvatakse hankijale või pakkujale kui
                      valitakse võitja.
                    </Tooltip>
                  }
                >
                  <img
                    id="contactPhone"
                    className="create-request__help"
                    src="/images/svg/help.svg"
                    alt="info"
                  />
                </OverlayTrigger>
              </div>

              <div className="settings__profile-text">
                {user.contactPhone ? user.contactPhone : "–"}
              </div>
            </div>

            <div className="settings__footer">
              <CustomAccordionToggle>Loobu</CustomAccordionToggle>
              <Button
                className="float-end"
                variant="warning"
                type="button"
                onClick={this.openEditingView}
              >
                Muuda
              </Button>
            </div>
          </>
        )}

        {editing && (
          <Form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-2">
                  <Form.Label htmlFor="forename">Eesnimi</Form.Label>
                  <Form.Control
                    id="forename"
                    name="forename"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.user.forename}
                    isInvalid={!!errors.forenameError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.forenameError}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-2">
                  <Form.Label htmlFor="surname">Perenimi</Form.Label>
                  <Form.Control
                    id="surname"
                    name="surname"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.user.surname}
                    isInvalid={!!errors.surnameError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.surnameError}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-2">
                  <Form.Label className="mb-0 d-flex" htmlFor="contactPhone">
                    Kontakt telefoni number
                  </Form.Label>
                  <Form.Text muted>
                    Telefoni number, mis kuvatakse hankijale või pakkujale kui
                    valitakse võitja.
                  </Form.Text>
                  <Form.Control
                    id="contactPhone"
                    name="contactPhone"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.user.contactPhone}
                    isInvalid={!!errors.contactPhoneError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contactPhoneError}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group className="mb-2">
                  <Form.Label className="mb-0 d-flex" htmlFor="contactEmail">
                    Kontakt e-posti aadress
                  </Form.Label>
                  <Form.Text muted>
                    Alternatiivne e-posti aadress mis kuvatakse hankijale või
                    pakkujale kui valitakse võitja. Lisaks hakkab see olema
                    e-posti aadress kuhu saadetakse teavitusi.
                  </Form.Text>
                  <Form.Control
                    id="contactEmail"
                    name="contactEmail"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.user.contactEmail}
                    isInvalid={!!errors.contactEmailError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contactEmailError}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="settings__footer">
              <Button
                className="mr-3"
                variant="light"
                type="button"
                onClick={this.closeEditingView}
              >
                Loobu
              </Button>
              <Button
                className="float-end rounded"
                variant="warning"
                onClick={this.handleSubmit}
              >
                Salvesta
              </Button>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUser: (user) => {
      dispatch(userActions.success(user));
    },
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
