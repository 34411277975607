import * as api from "../services/turgoil-api";

const USER_CONVERSATIONS_LOADING = "user-conversations/fetching";
const USER_CONVERSATIONS_SUCCESS = "user-conversations/success";
const USER_CONVERSATIONS_RESET = "user-conversations/reset";

export type UserConversationState = {
  loading: boolean;
  conversations: any[];
};

const initialState: UserConversationState = {
  loading: false,
  conversations: [],
};

export default function reducer(
  state = initialState,
  action:
    | UserConversationSuccess
    | UserConversationLoading
    | UserConversationReset,
) {
  switch (action.type) {
    case USER_CONVERSATIONS_RESET:
      return {
        conversations: null,
        loading: false,
      };
    case USER_CONVERSATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_CONVERSATIONS_SUCCESS:
      return {
        conversations: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

type UserConversationLoading = {
  type: typeof USER_CONVERSATIONS_LOADING;
};

type UserConversationSuccess = {
  type: typeof USER_CONVERSATIONS_SUCCESS;
  payload: any;
};

type UserConversationReset = {
  type: typeof USER_CONVERSATIONS_RESET;
};

export function userConversationsReset(): UserConversationReset {
  return { type: USER_CONVERSATIONS_RESET };
}

export function userConversationsLoading(): UserConversationLoading {
  return { type: USER_CONVERSATIONS_LOADING };
}

export function userConversationsSuccess(
  conversations,
): UserConversationSuccess {
  return {
    type: USER_CONVERSATIONS_SUCCESS,
    payload: conversations,
  };
}

export function getUserConversations() {
  return (dispatch) => {
    dispatch(userConversationsLoading());
    return api.getOilRequestConversations().then((conversations) => {
      dispatch(userConversationsSuccess(conversations));
      return conversations;
    });
  };
}
