const ADD_TOAST = "toasts/add";

const initialState = {
  toasts: [],
};

export type Toast = {
  title: string;
  text: string;
  type: string;
};

export type ToastState = {
  toasts: Toast[];
};

export default function reducer(state = initialState, action: AddToast) {
  switch (action.type) {
    case ADD_TOAST:
      return {
        toasts: [...state.toasts, action.payload],
      };
    default:
      return state;
  }
}

type AddToast = {
  type: typeof ADD_TOAST;
  payload: Toast;
};

/**
 * @param {Object} toast
 * @param {string} toast.title
 * @param {string} toast.text
 * @param {string} toast.type
 */
export function showToast(toast: Toast): AddToast {
  return {
    type: ADD_TOAST,
    payload: toast,
  };
}
