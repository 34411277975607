import React from "react";
import { useSelector } from "react-redux";
import LandingPage from "../LandingPage";
import HomePage from "../HomePage";

const IndexPage = (): React.ReactElement => {
  const user = useSelector((state: any) => state.userReducer.user);

  return (
    <>
      {!user && <LandingPage />}
      {user && <HomePage />}
    </>
  );
};

export default IndexPage;
