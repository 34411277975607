// eslint-disable-next-line import/prefer-default-export
export const Routes = Object.freeze({
  Advertisement: "/advertisement",
  OilRequestCreate: "/oil-requests/create",
  FrequentlyAskedQuestions: "/frequently-asked-questions",
  Home: "/",
  Instructions: "/instructions",
  SignIn: "/signin",
  Conversations: "/conversations",
  OilRequest: "/oil-requests/[id]",
  OilRequests: "/oil-requests",
  AccountRecovery: "/account-recovery",
  SignUp: "/signup",
  Settings: "/settings",
  TermsAndConditions: "/terms-and-conditions",
});
