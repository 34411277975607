import { createStore, applyMiddleware, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import { thunk } from "redux-thunk";

import toastsReducer, { ToastState } from "./toasts";
import userReducer, { UserState } from "./user";
import userCompaniesReducer, { UserCompanyState } from "./user-companies";
import userConversationsReducer, {
  UserConversationState,
} from "./user-conversations";
import userNotificationsReducer, {
  UserNotificationState,
} from "./user-notifications";

export type RootState = {
  userReducer: UserState;
  toastsReducer: ToastState;
  userCompaniesReducer: UserCompanyState;
  userConversationsReducer: UserConversationState;
  userNotificationsReducer: UserNotificationState;
};

const reducers = combineReducers({
  userReducer,
  toastsReducer,
  userCompaniesReducer,
  userConversationsReducer,
  userNotificationsReducer,
});

const middleware = [thunk];
if (process.env.NODE_ENV === "development") {
  middleware.push(createLogger());
}

export const configureStore = (preloadedState: Partial<RootState>) =>
  createStore(reducers, preloadedState, applyMiddleware(...middleware));
