import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Loader from "../../../common/Loader";
import { createUser } from "../../../services/turgoil-api";
import { validateEmail } from "../../../utils/regex";

type Props = {
  nextStep: () => void;
};

type User = {
  email: string;
  password: string;
  passwordAgain: string;
  forename: string;
  surname: string;
  termsAccept: boolean;
};

type State = {
  user: User;
  errors: Record<string, string>;
  loading: boolean;
};

class SignUpForm extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: "",
        password: "",
        passwordAgain: "",
        forename: "",
        surname: "",
        termsAccept: false,
      },
      errors: {},
      loading: false,
    };
  }

  handleUserChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [`${name}Error`]: "",
      },
    }));
  };

  handleEmailBlur = () => {
    const { email } = this.state.user;
    if (!validateEmail(email)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          emailError:
            "See e-posti aadress ei ole korrektne. Korrektne on näiteks info@turgoil.com.",
        },
      }));
    }
  };

  validateForm = () => {
    const { email, password, passwordAgain, forename, surname, termsAccept } =
      this.state.user;
    const errors: Record<string, string> = {};

    if (!validateEmail(email)) {
      errors.emailError =
        "See e-posti aadress ei ole korrektne. Korrektne on näiteks info@turgoil.com.";
    }
    if (password.length < 5) {
      errors.passwordError = "Parooli pikkus peab olema vähemalt 5 tähemärki.";
    }
    if (password !== passwordAgain) {
      errors.passwordError = "Paroolid peavad olema samad.";
    }
    if (!termsAccept) {
      errors.termsError = "Kasutustingimustega nõustumine on vajalik.";
    }
    if (!forename) {
      errors.forenameError = "Eesnimi on kohustuslik.";
    }
    if (!surname) {
      errors.surnameError = "Perekonnanimi on kohustuslik.";
    }

    this.setState({
      errors,
    });

    return Object.keys(errors).length === 0;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { nextStep } = this.props;
    const { user } = this.state;

    if (!this.validateForm()) {
      return;
    }

    this.setState({ loading: true });
    createUser({
      email: user.email,
      password: user.password,
      forename: user.forename,
      surname: user.surname,
    })
      .then(() => {
        this.setState({ loading: false });
        nextStep();
      })
      .catch((err) => {
        if (
          err.type === "https://turgoil.com/problems/user-already-registered"
        ) {
          this.setState({
            errors: {
              emailError:
                "Sellise e-posti aadressiga kasutaja on juba registreeritud.",
            },
            loading: false,
          });

          return;
        }

        this.setState({
          loading: false,
        });
      });
  };

  handleTermsToggle = () => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        termsAccept: !prevState.user.termsAccept,
      },
      errors: {
        ...prevState.errors,
        termsError: "",
      },
    }));
  };

  render() {
    const { user, errors, loading } = this.state;

    return (
      <>
        {loading && <Loader />}
        {!loading && (
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="email">E-post</Form.Label>
              <Form.Control
                name="email"
                id="email"
                value={user.email}
                onChange={this.handleUserChange}
                onBlur={this.handleEmailBlur}
                isInvalid={!!errors.emailError}
              />
              <Form.Control.Feedback type="invalid">
                {errors.emailError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="password">Parool</Form.Label>
              <Form.Control
                name="password"
                id="password"
                type="password"
                value={user.password}
                onChange={this.handleUserChange}
                isInvalid={!!errors.passwordError}
              />
              <Form.Control.Feedback type="invalid">
                {errors.passwordError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="passwordAgain">Parool uuesti</Form.Label>
              <Form.Control
                name="passwordAgain"
                id="passwordAgain"
                type="password"
                value={user.passwordAgain}
                onChange={this.handleUserChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="forename">Eesnimi</Form.Label>
              <Form.Control
                name="forename"
                id="forename"
                value={user.forename}
                onChange={this.handleUserChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="surname">Perekonnanimi</Form.Label>
              <Form.Control
                name="surname"
                id="surname"
                value={user.surname}
                onChange={this.handleUserChange}
              />
            </Form.Group>
            <Form.Group controlId="test1234" className="mb-2">
              <Form.Check>
                <Form.Check.Input
                  isInvalid={!!errors.termsError}
                  type="checkbox"
                  onClick={this.handleTermsToggle}
                  checked={this.state.user?.termsAccept}
                />
                <Form.Check.Label>
                  Olen lugenud ja nõustun Turgoil.com&nbsp;
                  <Link to="/terms-and-conditions">
                    <a
                      className="login-page__link"
                      target="_blank"
                      href="/terms-and-conditions"
                    >
                      kasutustingimustega.
                    </a>
                  </Link>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>

            <Button variant="warning" type="submit">
              Loo kasutaja
            </Button>
          </Form>
        )}
      </>
    );
  }
}

export default SignUpForm;
