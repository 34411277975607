import React from "react";
import { Helmet } from "react-helmet";

import LandingSlogan from "./sub/LandingSlogan";

import Footer from "../../common/Footer";
import Header from "../../common/Header";

function getCorrectText(eventKey) {
  switch (eventKey) {
    case 1:
      return {
        title: "Hangete ülevaade",
        content: "Põhjalik ülevaade kütuse hangetest.",
      };
    case 2:
      return {
        title: "Hanke vaade",
        content: `Selles vaates valib kütuse hankija hankele võitja olemasolevate pakkumiste põhjal.
					Kui pakkujad on esitanud küsimusi hankijale, siis hanke vaates on võimalik neile
					vastata.`,
      };
    case 3:
      return {
        title: "Vestlused",
        content: `Turgoil saadab hankega seoses teavitusi pakkujale ja hankijale.
					Pärast hanke võitja välja kuulutamist saavad kütuse hankija ja pakkuja omavahel vestelda.`,
      };
    default:
      return {
        title: "Hanke korraldamine",
        content: `Võimalik korraldada kütuse hange mitmele kütusele korraga. Hanke saab korraldada
					eraisikuna kui ka juriidilise isikuna.  Hanke korraldamine on imelihtne ning see jõuab
					pakkujateni mõne sekundi jooksul.`,
      };
  }
}

type State = {
  title: string;
  content: string;
};

class LandingPage extends React.PureComponent<any, State> {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      content: "",
      ...getCorrectText(0),
    };
  }

  handleCarousel = (eventKey) => {
    this.setState({
      ...getCorrectText(eventKey),
    });
  };

  render() {
    const { title, content } = this.state;

    return (
      <div className="landing-page">
        <Helmet>
          <title>
            Turgoil - vedelkütuse hanked: bensiin 95, bensiin 98, diislikütus,
            erimärgistusega diislikütus
          </title>
          <meta
            name="description"
            content="Soovid osta diislikütust, bensiin 95, bensiin 98
						või erimärgistusega diislikütust? Korralda vedelkütuse hange
						Turgoilis, Eesti vedelkütuse hankeid vahendav veebikeskkond."
          />
        </Helmet>
        <div className="landing-page__content">
          <div className="landing-page__header">
            <Header />
          </div>
          <div className="container mb-5">
            <LandingSlogan />
          </div>
        </div>
        <div className="container">
          <div className="landing-page__benefits">
            <div className="landing-page__benefit">
              <img src="images/svg/clock.svg" alt="Säästa aega" />
              <h5 className="landing-page__benefit-title">Säästa aega</h5>
              <p>
                Vedelkütuse hanke korraldamine on imelihtne ja jõuab pakkujateni
                mõne sekundi jooksul.
              </p>
            </div>
            <div className="landing-page__benefit">
              <img src="images/svg/reliability.svg" alt="Usaldusväärne" />
              <h5 className="landing-page__benefit-title">Usaldusväärne</h5>
              <p>
                Pakkumisi saavad teha ainult ettevõtted, kes on tegutsenud Eesti
                kütuseturul mitmeid aastaid.
              </p>
            </div>
            <div className="landing-page__benefit">
              <img src="images/svg/payment.svg" alt="Säästa raha" />
              <h5 className="landing-page__benefit-title">Säästa raha</h5>
              <p>
                Vedelkütuse hankele tehakse mitmeid pakkumisi, mille seast saab
                hanke korraldaja valida sobivaima pakkumise.
              </p>
            </div>
          </div>
          {/* <h5 className="text-center mb-3">Ülevaade keskkonnast</h5> */}
          {/* <p className="landing-page__overview"> */}
          {/* 	<b>{title} &mdash; </b> */}
          {/* 	{content} */}
          {/* </p> */}
          {/* <Carousel */}
          {/* 	onSelect={this.handleCarousel} */}
          {/* 	interval={10000} */}
          {/* 	indicators */}
          {/* 	controls={false} */}
          {/* > */}
          {/* 	<Carousel.Item> */}
          {/* 		<img */}
          {/* 			src="/images/turgoil-create-oil-request.png" */}
          {/* 			alt="Create oil request" */}
          {/* 		/> */}
          {/* 	</Carousel.Item> */}
          {/* 	<Carousel.Item> */}
          {/* 		<img */}
          {/* 			src="/images/turgoil-oil-requests.png" */}
          {/* 			alt="Oil requests" */}
          {/* 		/> */}
          {/* 	</Carousel.Item> */}
          {/* 	<Carousel.Item> */}
          {/* 		<img */}
          {/* 			src="/images/turgoil-view-oil-request.png" */}
          {/* 			alt="Oil request view" */}
          {/* 		/> */}
          {/* 	</Carousel.Item> */}
          {/* 	<Carousel.Item> */}
          {/* 		<img */}
          {/* 			src="/images/turgoil-conversations.png" */}
          {/* 			alt="Conversations" */}
          {/* 		/> */}
          {/* 	</Carousel.Item> */}
          {/* </Carousel> */}
        </div>
        <div className="container landing-page__footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default LandingPage;
