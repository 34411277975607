import { UserRole } from "./config";

/**
 * Check whether the user has authenticated.
 */
export function hasAuthenticated() {
  return window.sessionStorage.getItem("jwt") !== null;
}

export function storeJWTAtSessionStorage(authToken) {
  window.sessionStorage.setItem("jwt", authToken);
}

export function destroyAuthentication() {
  window.sessionStorage.removeItem("jwt");
}

export function getAuthenticationToken() {
  return window.sessionStorage.getItem("jwt");
}

export function getRoleDisplayName(role: string): string {
  if (UserRole.Requester === role) {
    return "Hankija";
  }
  if (UserRole.Bidder === role) {
    return "Pakkuja";
  }
  if (UserRole.Agent === role) {
    return "Agent";
  }
  if (UserRole.Admin === role) {
    return "Adminstraator";
  }
}
