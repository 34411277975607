import React from "react";
import Big from "big.js";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";

import { numberWithSpaces } from "../../../utils/format";
import {
  getOilRequest,
  getOilRequestBids,
  createOilRequestConversationMessage,
  getOilRequestConversation,
} from "../../../services/turgoil-api";
import Oils from "../../../common/Oils";
import { getDateTime } from "../../../utils/date";
import Message from "./Message";
import { getDisplayValueWithTax } from "../../OilRequestPage/sub/CreateOrEditBid";

type MapStateToProps = {
  user: any;
  userCompanies: any[];
  userConversations: any[];
};

type Props = {
  conversationId: number;
  oilRequestId: number;
  onConversationUpdate: (c: any) => void;
} & MapStateToProps;

type State = {
  loading: boolean;
  conversation: any;
  oilRequest: any;
  oilRequestBids: any[];
  message: string;
};

class ConversationMessages extends React.PureComponent<Props, State> {
  private ref: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      conversation: null,
      oilRequest: null,
      oilRequestBids: [],
      message: "",
    };
  }

  componentDidMount() {
    this.fetchConversation();
  }

  componentDidUpdate(prevProps) {
    const { conversationId, userConversations } = this.props;

    if (
      prevProps.conversationId !== conversationId ||
      !this.isConversationRead(conversationId, userConversations)
    ) {
      this.fetchConversation();
    }
  }

  isConversationRead = (conversationId, conversations) => {
    const { userCompanies, user } = this.props;

    const foundConversation = conversations.find(
      (conv) => conv.id === conversationId,
    );
    if (!foundConversation) {
      return true;
    }

    const lastMessage = foundConversation.messages.slice(-1).pop();
    if (!lastMessage) {
      return true;
    }
    if (lastMessage.from.userId === user.id) {
      return true;
    }
    if (
      lastMessage.from.companyId &&
      userCompanies.some((company) => company.id === lastMessage.from.companyId)
    ) {
      return true;
    }

    return Boolean(lastMessage.readDatetime);
  };

  fetchConversation = () => {
    const { conversationId, oilRequestId, onConversationUpdate } = this.props;

    const promises = [
      getOilRequestConversation(conversationId),
      getOilRequest(oilRequestId),
      getOilRequestBids(oilRequestId),
    ];

    Promise.all(promises)
      .then(([conversation, oilRequest, oilRequestBids]) => {
        // Conversation with latest 'readDatetime' attributes
        onConversationUpdate(conversation);

        this.setState({
          conversation,
          oilRequest,
          oilRequestBids,
        });
      })
      .finally(() => {
        this.setState({ loading: false }, () => {
          if (this.ref.current) {
            this.ref.current.scrollTop = this.ref.current.scrollHeight;
          }
        });
      });
  };

  getMessagePosition = (message) => {
    const { user, userCompanies } = this.props;

    if (user.id === message.from.userId) {
      return "right";
    }

    if (message.from.companyId) {
      if (
        userCompanies.some((company) => company.id === message.from.companyId)
      ) {
        return "right";
      }
    }

    return "left";
  };

  sendMessage = async () => {
    const { conversationId, onConversationUpdate } = this.props;
    const { message } = this.state;

    if (!message) {
      return;
    }
    this.setState({
      message: "",
    });

    await createOilRequestConversationMessage(conversationId, message);
    const conversation = await getOilRequestConversation(conversationId);

    this.setState({ conversation }, () => {
      if (this.ref.current) {
        this.ref.current.scrollTop = this.ref.current.scrollHeight;
      }
    });

    onConversationUpdate(conversation);
  };

  handleChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  handleMessageSubmit = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      this.sendMessage();
    }
  };

  getWinningPrice = () => {
    const { oilRequest, oilRequestBids } = this.state;
    const foundBid = oilRequestBids.find(
      (bid) => bid.companyId === oilRequest.winner.company.id,
    );
    if (!foundBid) {
      return undefined;
    }

    return `${numberWithSpaces(parseFloat(foundBid.price).toFixed(2))} €`;
  };

  getWinningPriceWithTax = () => {
    const { oilRequest, oilRequestBids } = this.state;
    const foundBid = oilRequestBids.find(
      (bid) => bid.companyId === oilRequest.winner.company.id,
    );
    if (!foundBid) {
      return undefined;
    }

    return `${numberWithSpaces(getDisplayValueWithTax(foundBid.price))} €`;
  };

  getMarkup = () => {
    const { oilRequest } = this.state;
    return `${numberWithSpaces(parseFloat(oilRequest.markup).toFixed(2))} €`;
  };

  getTotal = () => {
    const { oilRequest, oilRequestBids } = this.state;
    const foundBid = oilRequestBids.find(
      (bid) => bid.companyId === oilRequest.winner.company.id,
    );
    if (!foundBid) {
      return undefined;
    }

    return `${numberWithSpaces(new Big(foundBid.price || 0).plus(oilRequest.markup || 0).toFixed(2))} €`;
  };

  getTotalWithTax = () => {
    const { oilRequest, oilRequestBids } = this.state;
    const foundBid = oilRequestBids.find(
      (bid) => bid.companyId === oilRequest.winner.company.id,
    );
    if (!foundBid) {
      return undefined;
    }

    return `${numberWithSpaces(getDisplayValueWithTax(new Big(foundBid.price || 0).plus(oilRequest.markup || 0), 2))} €`;
  };

  render() {
    const { loading, conversation, oilRequest, message } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <div className="conversation__messages" ref={this.ref}>
          <div className="text-center">
            <div>
              Hange:&nbsp;
              <Link to={`/oil-requests/${conversation.oilRequestId}`}>
                <a href={`/oil-requests/${conversation.oilRequestId}`}>
                  #{conversation.oilRequestId}
                </a>
              </Link>
            </div>
            <div>
              Hanke korraldaja:{" "}
              {oilRequest.company
                ? oilRequest.company.name
                : oilRequest.user.name}
            </div>
            <div>Hanke võtija: {oilRequest.winner.company.name}</div>
            <div>Pakkumine: {this.getWinningPrice()}</div>
            {!oilRequest.markup && (
              <div>Pakkumine käibemaksuga: {this.getWinningPriceWithTax()}</div>
            )}
            {oilRequest.markup && <div>Juurdehindlus: {this.getMarkup()}</div>}
            {oilRequest.markup && <div>Summa: {this.getTotal()}</div>}
            {oilRequest.markup && (
              <div>Summa käibemaksuga: {this.getTotalWithTax()}</div>
            )}
            <Oils className="justify-content-center" oils={oilRequest.oils} />
          </div>
          <hr />
          {conversation.messages.map((msg) => (
            <Message
              position={this.getMessagePosition(msg)}
              key={msg.id}
              content={msg.message}
              datetime={getDateTime(msg.regDatetime)}
            />
          ))}
        </div>
        <div
          className="conversation__messages-form"
          onSubmit={this.sendMessage}
        >
          <div className="conversation__messages-form__textarea">
            <Form.Control
              as="textarea"
              rows={2}
              name="message"
              value={message}
              onKeyDown={this.handleMessageSubmit}
              onChange={this.handleChange}
            />
          </div>
          <div>
            <i
              tabIndex={0}
              className={classNames(
                "fa fa-paper-plane fa-2x conversation__send",
                { "conversation__send--disabled": !message.length },
              )}
              onClick={this.sendMessage}
              onKeyDown={this.handleMessageSubmit}
            />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    userCompanies: state.userCompaniesReducer.companies,
    userConversations: state.userConversationsReducer.conversations,
  };
}

export default connect(mapStateToProps)(ConversationMessages);
