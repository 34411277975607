import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { activateCompany } from "../services/turgoil-api";
import { showToast } from "../state/toasts";
import { ToastType } from "../common/Toasts";
import { Routes } from "../utils/routes";
import { useDispatch } from "react-redux";

const CompanyActivationPage: React.FC = (): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activationCode = searchParams.get("activationCode");
    if (activationCode) {
      activateCompany(activationCode)
        .then(() => {
          dispatch(
            showToast({
              title: "Päring õnnestus.",
              text: "Teie ettevõte on edukalt kinnitatud! Saate korraldada vedelkütuse hange.",
              type: ToastType.Success,
            }),
          );
          navigate(Routes.Settings, { replace: true });
        })
        .catch(() => {
          navigate(Routes.Settings);
        });
    }
  }, []);

  return null;
};

export default CompanyActivationPage;
