import React from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

import { PictureLayoutForm } from "../../common/Layout";
import StartRecovery from "./sub/StartRecovery";
import Recover from "./sub/Recover";

const AccountRecoveryPage = (): React.ReactElement => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  return (
    <PictureLayoutForm>
      <Helmet>
        <title>
          Turgoil - Salasõna taastamine / Unustasid salasõna / Ei saa sisse
          logida
        </title>
        <meta
          name="description"
          content="Selleks, et taastada oma salasõna sisesta oma e-posti aadress."
        />
      </Helmet>
      <h2>Salasõna taastamine</h2>
      <p>
        <span>Kas sul on juba kasutaja? </span>
        <Link to="/signin">
          <a className="login-page__link" href="/signin">
            Logi sisse
          </a>
        </Link>
      </p>

      {!query.get("recoveryCode") && <StartRecovery />}
      {query.get("recoveryCode") && <Recover />}
    </PictureLayoutForm>
  );
};

export default AccountRecoveryPage;
