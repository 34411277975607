import React from "react";
import classNames from "classnames";
import { Toast as BToast } from "react-bootstrap";

type Props = {
  title: string;
  text: string;
  bg: string;
};

type State = {
  open: boolean;
};

class Toast extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  render() {
    const { open } = this.state;
    const { title, text, bg } = this.props;

    return (
      <BToast
        onClose={() => this.setState({ open: false })}
        show={open}
        delay={10000}
        className={classNames("d-inline-block m-1")}
        autohide
        bg={bg}
      >
        <BToast.Header>
          <strong className="me-auto">{title}</strong>
        </BToast.Header>
        <BToast.Body className="text-white">{text}</BToast.Body>
      </BToast>
    );
  }
}

export default Toast;
