import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { getNameForOil } from "../../../common/Oils";
import Select from "../../../common/Select";
import { OilRequestOilType } from "../../../utils/config";
import { oilMarkingOptions } from "../../CreateOilRequestPage/helpers";
import { getDisplayValueWithTax } from "./CreateOrEditBid";

export type Bid = {
  oilId: number;
  price: string;
  marking?: string;
  cloudPoint?: string;
  coldFilterPlugPoint?: string;
};

type Props = {
  oil: {
    oilType: string;
  };
  bid: Bid;
  onChange: (bid: Bid) => void;
  errors?: Partial<Record<keyof Omit<Bid, "oilId">, string>>;
};

const BidForm: React.FC<Props> = ({
  oil,
  bid,
  errors,
  onChange,
}): React.ReactElement => {
  const [dirtyBid, setDirtyBid] = useState<Bid>({
    ...bid,
  });

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDirtyBid({
      ...dirtyBid,
      [name]: value,
    });
  };

  const handleSelectChange = (
    o: { value: any } | null,
    m: { name?: string },
  ) => {
    const { value } = o || {};
    const { name } = m;
    setDirtyBid({
      ...dirtyBid,
      [name]: value,
    });
  };

  const isCloudPoint = (): boolean => {
    return (
      ([
        String(OilRequestOilType.DieselSpecial),
        String(OilRequestOilType.Diesel),
      ].includes(oil.oilType) &&
        ["A0", "A1", "A2", "HVO"].includes(dirtyBid.marking)) ||
      oil.oilType === OilRequestOilType.HydrotreatedVegetableOil
    );
  };

  const isColdFilterPlugPoint = (): boolean => {
    return (
      ([
        String(OilRequestOilType.DieselSpecial),
        String(OilRequestOilType.Diesel),
      ].includes(oil.oilType) &&
        ["E", "F", "A0", "A1", "A2", "HVO"].includes(dirtyBid.marking)) ||
      oil.oilType === OilRequestOilType.HydrotreatedVegetableOil
    );
  };

  useEffect(() => {
    onChange(dirtyBid);
  }, [dirtyBid, onChange]);

  return (
    <>
      <div className="col-sm-3">
        <h6 className="oil-request-page__title-orange">
          {getNameForOil(oil.oilType)}
        </h6>
        <div>
          <Form.Label>Pakkumine</Form.Label>
          <InputGroup className="mb-2">
            <Form.Control
              type="number"
              onChange={handleTextChange}
              name="price"
              value={dirtyBid.price}
              isInvalid={!!errors?.price}
              step={0.0001}
            />
            <InputGroup.Text>EUR</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors?.price}
            </Form.Control.Feedback>
          </InputGroup>
        </div>
        <div>
          <Form.Label>Pakkumine 22%</Form.Label>
          <InputGroup className="mb-2">
            <Form.Control
              type="number"
              onChange={handleTextChange}
              name="priceWithTax"
              value={
                getDisplayValueWithTax(dirtyBid.price) !== "0"
                  ? getDisplayValueWithTax(dirtyBid.price, 4)
                  : ""
              }
              step={0.0001}
              disabled
            />
            <InputGroup.Text>EUR</InputGroup.Text>
          </InputGroup>
        </div>
        {[
          String(OilRequestOilType.DieselSpecial),
          String(OilRequestOilType.Diesel),
        ].includes(oil.oilType) && (
          <div>
            <Form.Label>Märgistus</Form.Label>
            <Form.Group className="mb-2">
              <Select
                isClearable
                onChange={handleSelectChange}
                name="marking"
                placeholder=""
                options={oilMarkingOptions}
                isInvalid={!!errors?.marking}
                value={oilMarkingOptions.find(
                  (m) => m.value === dirtyBid.marking,
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.marking}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        )}
        {isCloudPoint() && (
          <div>
            <Form.Label>CP</Form.Label>
            <Form.Group className="mb-2">
              <Form.Control
                type="number"
                onChange={handleTextChange}
                name="cloudPoint"
                isInvalid={!!errors?.cloudPoint}
                value={dirtyBid.cloudPoint}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.cloudPoint}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        )}
        {isColdFilterPlugPoint() && (
          <div>
            <Form.Label>CFFP</Form.Label>
            <Form.Group className="mb-2">
              <Form.Control
                type="number"
                onChange={handleTextChange}
                name="coldFilterPlugPoint"
                isInvalid={!!errors?.coldFilterPlugPoint}
                value={dirtyBid.coldFilterPlugPoint}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.coldFilterPlugPoint}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        )}
      </div>
    </>
  );
};

export default BidForm;
