import * as React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import Bid from "./Bid";

type OilRequestBid = {
  markup: string;
  id: string;
} & Record<string, any>;

type OilRequest = {
  markup?: string;
  status: string;
  user: {
    id: number;
  };
  oils: any[];
};

type Props = {
  oilRequestBids: OilRequestBid[];
  oilRequest: OilRequest;
  onWinner: (bidId: string, markup?: string) => void;
};

const OilRequestBidTable: React.FC<Props> = ({
  oilRequestBids,
  oilRequest,
  onWinner,
}): React.ReactElement => {
  const user = useSelector((state: RootState) => state.userReducer.user);

  if (!oilRequestBids || oilRequestBids.length === 0) {
    return <div>Ei ole tehtud ühtegi pakkumist.</div>;
  }

  return (
    <>
      <Table responsive className="d-none d-md-table">
        <thead>
          <tr>
            <th className="fw-normal">Pakkuja</th>
            <th className="fw-normal">Pakkumine</th>
            {!oilRequest.markup && <th className="fw-normal">Pakkumine 22%</th>}
            <th className="fw-normal">Kommentaar</th>
            {oilRequest.markup && <th className="fw-normal">Juurdehindlus</th>}
            {oilRequest.markup && <th className="fw-normal">Summa</th>}
            {oilRequest.markup && <th className="fw-normal">Summa 22%</th>}
            <th className="fw-normal">Lisatud</th>
            <th className="fw-normal" style={{ width: 0 }} />
          </tr>
        </thead>
        <tbody>
          {oilRequestBids.map((b) => (
            <Bid
              key={b.id}
              requestStateCode={oilRequest.status}
              user={user}
              bid={b}
              oilRequestMarkup={oilRequest.markup}
              oilRequestOils={oilRequest.oils}
              onOilRequestWinner={onWinner}
              requesterId={oilRequest.user.id}
            />
          ))}
        </tbody>
      </Table>
      <div className="d-block d-md-none">
        {oilRequestBids.map((b, i) => (
          <React.Fragment key={b.id}>
            <Bid
              key={b.id}
              requestStateCode={oilRequest.status}
              user={user}
              bid={b}
              oilRequestMarkup={oilRequest.markup}
              oilRequestOils={oilRequest.oils}
              onOilRequestWinner={onWinner}
              requesterId={oilRequest.user.id}
            />
            {i !== oilRequestBids.length - 1 && (
              <hr className="oil-request-page__bid-description-mobile p-0 m-3" />
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default OilRequestBidTable;
