import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
// import { ReactComponent as Logo } from '../../images/svg/turgoil-logo-mono.svg';

export const PictureLayoutForm = (props) => (
  <>
    <div className="layout-picture-form">
      <div className="container">
        <div className="layout-picture-form__header">
          <Link to="/">
            <img src="/images/svg/turgoil-logo-black.svg" alt="logo" />
          </Link>
          <Link to="/" className="layout-picture-form__header__exit" />
        </div>
        <div className="layout-picture-form__right">
          <div className="layout-picture-form__right-container">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  </>
);

PictureLayoutForm.propTypes = {
  children: PropTypes.node.isRequired,
};
