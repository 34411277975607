import React, { PureComponent } from "react";
import DateTime from "react-datetime";
import classNames from "classnames";

import moment, { Moment } from "moment";
import "moment/locale/et";

moment.locale("et");

type Props = {
  isInvalid?: boolean;
  timeFormat?: boolean;
  initialViewDate?: any;
  onChange: (value: Moment | string) => void;
};

type State = {
  pickerOpen: boolean;
};

class Date extends PureComponent<Props, State> {
  private inputRef = React.createRef();

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      pickerOpen: false,
    };
  }

  disablePastDates = (current) => {
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  renderInput = (props) => {
    const { isInvalid } = this.props;

    return (
      <>
        <input
          {...props}
          ref={this.inputRef}
          className={classNames("form-control", { "is-invalid": isInvalid })}
        />
      </>
    );
  };

  render() {
    const { pickerOpen } = this.state;
    const { isInvalid, timeFormat, initialViewDate } = this.props;

    return (
      <DateTime
        onChange={this.props.onChange}
        initialViewDate={initialViewDate}
        className={classNames(
          "date-input",
          { "date-input--open": pickerOpen },
          { "is-invalid": isInvalid },
        )}
        isValidDate={this.disablePastDates}
        renderInput={this.renderInput}
        timeFormat={timeFormat}
      />
    );
  }
}

export default Date;
