import React, { PureComponent } from "react";
import moment from "moment";

import { getDateTimeDifference } from "../../../utils/date";

import Request from "./Request";

moment.locale("et");

type Props = {
  oilRequests: any[];
  onReload: (q: any) => void;
};

type State = {
  loading: boolean;
  time: string;
};

class OilRequestList extends PureComponent<Props, State> {
  private timer: number;

  constructor(props) {
    super(props);

    this.timer = window.setInterval(() => this.tick(), 1000);

    this.state = {
      time: moment().toString(),
      loading: false,
    };
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  list = () => {
    const { oilRequests } = this.props;

    const allOilRequests = oilRequests;

    const { time, loading } = this.state;

    return allOilRequests.map((request) => {
      let requestTimeLeft = null;

      if (request.status === "Active") {
        const diff = moment(request.endDatetime).diff(moment(time));
        if (diff < 0) {
          if (!loading) {
            this.setState({ loading: true });
            setTimeout(() => {
              this.props.onReload("");
              this.setState({ loading: false });
            }, 3000);
          }
          return null;
        }

        requestTimeLeft = getDateTimeDifference(request.endDatetime, time);
      }

      return (
        <Request
          key={request.id}
          requestTimeLeft={requestTimeLeft}
          request={request}
        />
      );
    });
  };

  tick() {
    this.setState({ time: moment().toString() });
  }

  render() {
    return this.list();
  }
}

export default OilRequestList;
