import React from "react";
import classNames from "classnames";

import { numberWithSpaces } from "../../utils/format";
import { OilRequestOilType, OilRequestOilUsageType } from "../../utils/config";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

/**
 * @param {string} oilType
 * @return {string}
 */
export function getNameForOil(oilType) {
  switch (oilType) {
    case OilRequestOilType.Petrol95:
      return "95";
    case OilRequestOilType.Petrol98:
      return "98";
    case OilRequestOilType.Diesel:
      return "D";
    case OilRequestOilType.DieselSpecial:
      return "EDK";
    case OilRequestOilType.HydrotreatedVegetableOil:
      return "HVO";
    default:
      return "";
  }
}

/**
 * @param {string} oilType
 * @return {string}
 */
export function getNameForOilUsage(oilType) {
  switch (oilType) {
    case OilRequestOilUsageType.Wholesale:
      return "Hulgimüük";
    case OilRequestOilUsageType.Agriculture:
      return "Põllumajandus";
    case OilRequestOilUsageType.Fishing:
      return "Kalapüük";
    default:
      return "";
  }
}

type Props = {
  className?: string;
  oils: any[];
};

class Oils extends React.PureComponent<Props> {
  render() {
    const { className, oils } = this.props;

    return (
      <div className={classNames("oils", className)}>
        {oils.map((oil) => {
          const content = (
            <div className="oils__item badge bg-light text-dark">
              <div className="oils__item-title">
                {getNameForOil(oil.oilType)}
              </div>
              <div className="oils__item-content text-dark">
                <div>{numberWithSpaces(oil.amount)} L</div>
                <div>{getNameForOilUsage(oil.oilUsageType)}</div>
              </div>
            </div>
          );

          if (!oil.marking) {
            return content;
          }

          return (
            <OverlayTrigger
              key={oil.oilType}
              placement="top"
              overlay={<Tooltip>{oil.marking}</Tooltip>}
            >
              {content}
            </OverlayTrigger>
          );
        })}
      </div>
    );
  }
}

export default Oils;
