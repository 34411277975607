import React, { Component, useContext } from "react";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from "react-bootstrap";
import classNames from "classnames";

import ChangePassword from "./sub/ChangePassword";
import Company from "./sub/Company";
import Profile from "./sub/Profile";

const SettingSection = {
  Profile: "Profile",
  ChangePassword: "ChangePassword",
  Company: "Company",
};

function CustomAccordionToggle({
  children,
  eventKey,
  callback,
}: {
  children: React.ReactNode;
  eventKey: string;
  callback?: (e: string) => void;
}) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className={classNames("collapse-button", { active: isCurrentEventKey })}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

class SettingsPage extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Turgoil - Seaded</title>
        </Helmet>
        <h3 className="page-content__title">Seaded</h3>
        <div className="settings">
          <Accordion>
            <CustomAccordionToggle eventKey={SettingSection.Profile}>
              <div className="image-circle">
                <img src="images/svg/avatar.svg" alt="avatar" />
              </div>
              <div className="collapse-header">
                <h5>Konto</h5>
                Vaata oma profiili
              </div>
            </CustomAccordionToggle>
            <Accordion.Collapse eventKey={SettingSection.Profile}>
              <Profile />
            </Accordion.Collapse>

            <CustomAccordionToggle eventKey={SettingSection.ChangePassword}>
              <div className="image-circle">
                <img src="images/svg/padlock.svg" alt="padlock" />
              </div>
              <div className="collapse-header">
                <h5>Muuda parooli</h5>
                *******
              </div>
            </CustomAccordionToggle>
            <Accordion.Collapse eventKey={SettingSection.ChangePassword}>
              <ChangePassword />
            </Accordion.Collapse>

            <CustomAccordionToggle eventKey={SettingSection.Company}>
              <div className="image-circle">
                <img src="images/svg/company.svg" alt="company" />
              </div>
              <div className="collapse-header">
                <h5>Ettevõte</h5>
                Halda oma ettevõtteid
              </div>
            </CustomAccordionToggle>
            <Accordion.Collapse eventKey={SettingSection.Company}>
              <Company />
            </Accordion.Collapse>
          </Accordion>
        </div>
      </>
    );
  }
}

export default SettingsPage;
