export const oilRequestTransportOptions = [
  {
    value: "Bidder",
    label: "Võitja",
  },
  {
    value: "Requester",
    label: "Korraldaja",
  },
];

export const oilPurposeOptions = [
  {
    value: "Agriculture",
    label: "Põllumajandus",
  },
  {
    value: "Fishing",
    label: "Kalapüük",
  },
  {
    value: "Wholesale",
    label: "Hulgimüük",
  },
];

export const oilMarkingOptions = [
  {
    value: "C",
    label: "Suvine - C",
  },
  {
    value: "E",
    label: "Üleminek - E",
  },
  {
    value: "F",
    label: "Üleminek - F",
  },
  {
    value: "A0",
    label: "Talvine - A0",
  },
  {
    value: "A1",
    label: "Talvine - A1",
  },
  {
    value: "A2",
    label: "Talvine - A2",
  },
  {
    value: "HVO",
    label: "HVO",
  },
];

export const oilAdditiveOptions = [
  {
    value: "Yes",
    label: "Jah",
  },
  {
    value: "No",
    label: "Ei",
  },
];

export const oilRequestLocationOptions = [
  {
    value: "Hiiumaa",
    label: "Hiiumaa",
  },
  {
    value: "Harjumaa",
    label: "Harjumaa",
  },
  {
    value: "Ida-Virumaa",
    label: "Ida-Virumaa",
  },
  {
    value: "Jõgevamaa",
    label: "Jõgevamaa",
  },
  {
    value: "Järvamaa",
    label: "Järvamaa",
  },
  {
    value: "Läänemaa",
    label: "Läänemaa",
  },
  {
    value: "Lääne-Virumaa",
    label: "Lääne-Virumaa",
  },
  {
    value: "Põlvamaa",
    label: "Põlvamaa",
  },
  {
    value: "Pärnumaa",
    label: "Pärnumaa",
  },
  {
    value: "Raplamaa",
    label: "Raplamaa",
  },
  {
    value: "Saaremaa",
    label: "Saaremaa",
  },
  {
    value: "Tartumaa",
    label: "Tartumaa",
  },
  {
    value: "Valgamaa",
    label: "Valgamaa",
  },
  {
    value: "Viljandimaa",
    label: "Viljandimaa",
  },
  {
    value: "Võrumaa",
    label: "Võrumaa",
  },
];
