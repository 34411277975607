import * as api from "../services/turgoil-api";

const USER_NOTIFICATIONS_LOADING = "user-notifications/fetching";
const USER_NOTIFICATIONS_SUCCESS = "user-notifications/success";
const USER_NOTIFICATIONS_RESET = "user-notifications/logout";

export type UserNotificationState = {
  loading: boolean;
  notifications: any[];
};

const initialState = {
  loading: false,
  notifications: [],
};

export default function reducer(
  state = initialState,
  action:
    | UserNotificationSuccess
    | UserNotificationLoading
    | UserNotificationReset,
) {
  switch (action.type) {
    case USER_NOTIFICATIONS_RESET:
      return {
        notifications: null,
        loading: false,
      };
    case USER_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_NOTIFICATIONS_SUCCESS:
      return {
        notifications: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

type UserNotificationLoading = {
  type: typeof USER_NOTIFICATIONS_LOADING;
};

type UserNotificationSuccess = {
  type: typeof USER_NOTIFICATIONS_SUCCESS;
  payload: any;
};

type UserNotificationReset = {
  type: typeof USER_NOTIFICATIONS_RESET;
};

export function userNotificationsReset(): UserNotificationReset {
  return { type: USER_NOTIFICATIONS_RESET };
}

export function userNotificationsLoading(): UserNotificationLoading {
  return { type: USER_NOTIFICATIONS_LOADING };
}

export function userNotificationsSuccess(
  notifications,
): UserNotificationSuccess {
  return {
    type: USER_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
}

/**
 * @param {number} userId
 * @param {boolean} read - used to mark all the notifications as read
 */
export function getUserNotifications(userId, read = false) {
  return (dispatch) => {
    dispatch(userNotificationsLoading());
    return api.getUserNotifications(userId, read).then((notifications) => {
      dispatch(userNotificationsSuccess(notifications));
      return notifications;
    });
  };
}
