import React, { PureComponent, useContext } from "react";
import {
  Accordion,
  AccordionContext,
  Button,
  Tooltip,
  OverlayTrigger,
  useAccordionButton,
} from "react-bootstrap";

import Select from "../../../common/Select";
import { Tabs } from "..";
import { RouteComponentProps, withRouter } from "../../../common/withRouter";

const noop = () => undefined;

const options = {
  status: [
    {
      value: "Active",
      label: "Aktiivsed",
    },
    {
      value: "Closed",
      label: "Lõppenud",
    },
    {
      value: "Transport",
      label: "Teostamisel",
    },
    {
      value: "Decision",
      label: "Võitja valimine",
    },
  ],
  transport: [
    {
      value: "Requester",
      label: "Hankija poolt",
    },
    {
      value: "Bidder",
      label: "Võitja poolt",
    },
  ],
  address: [
    {
      value: "Hiiumaa",
      label: "Hiiumaa",
    },
    {
      value: "Harjumaa",
      label: "Harjumaa",
    },
    {
      value: "Ida-Virumaa",
      label: "Ida-Virumaa",
    },
    {
      value: "Jõgevamaa",
      label: "Jõgevamaa",
    },
    {
      value: "Järvamaa",
      label: "Järvamaa",
    },
    {
      value: "Läänemaa",
      label: "Läänemaa",
    },
    {
      value: "Lääne-Virumaa",
      label: "Lääne-Virumaa",
    },
    {
      value: "Põlvamaa",
      label: "Põlvamaa",
    },
    {
      value: "Pärnumaa",
      label: "Pärnumaa",
    },
    {
      value: "Raplamaa",
      label: "Raplamaa",
    },
    {
      value: "Saaremaa",
      label: "Saaremaa",
    },
    {
      value: "Tartumaa",
      label: "Tartumaa",
    },
    {
      value: "Valgamaa",
      label: "Valgamaa",
    },
    {
      value: "Viljandimaa",
      label: "Viljandimaa",
    },
    {
      value: "Võrumaa",
      label: "Võrumaa",
    },
  ],
  oil: [
    {
      value: "Petrol95",
      label: "Bensiin 95",
    },
    {
      value: "Petrol98",
      label: "Bensiin 98",
    },
    {
      value: "Diesel",
      label: "Diislikütus",
    },
    {
      value: "DieselSpecial",
      label: "Erimärgistusega diislikütus",
    },
  ],
};

function CustomAccordionToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>Filtrid</Tooltip>}>
      <Button
        type="button"
        className="navigation__action-button rounded-circle"
        variant={isCurrentEventKey ? "warning" : "outline-warning"}
        onClick={decoratedOnClick}
      >
        {children}
      </Button>
    </OverlayTrigger>
  );
}

function getArrayFromQuery(queryParameter) {
  if (queryParameter === undefined) {
    return [];
  }

  if (typeof queryParameter === "string") {
    return [queryParameter];
  }

  return queryParameter;
}

type OwnProps = {
  onSearch: any;
  user: any;
};

type Props = OwnProps & RouteComponentProps;

class OilRequestFilters extends PureComponent<Props, any> {
  constructor(props) {
    super(props);

    const { userIds } = props.params;

    let tab: any = Tabs.AllOilRequests;
    if (userIds) {
      tab = Tabs.RequesterOilRequests;
    }

    this.state = {
      tab,
      filter: {
        status: getArrayFromQuery(props.params.status),
        transport: getArrayFromQuery(props.params.transport),
        address: getArrayFromQuery(props.params.address),
        oil: getArrayFromQuery(props.params.oil),
        userIds: getArrayFromQuery(props.params.userIds),
      },
    };
  }

  handleFilterRequestStatusChange = (statuses) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        status: statuses.map((status) => status.value),
      },
    }));
  };

  handleFilterTransportChange = (transports) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        transport: transports.map((transport) => transport.value),
      },
    }));
  };

  handleFilterAddressChange = (addresses) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        address: addresses.map((address) => address.value),
      },
    }));
  };

  handleFilterOilChange = (oils) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        oil: oils.map((oil) => oil.value),
      },
    }));
  };

  handleSearch = () => {
    const { onSearch } = this.props;
    const { filter } = this.state;
    onSearch(filter);
  };

  handleTabChange = (e) => {
    const { user, onSearch } = this.props;
    const { filter } = this.state;

    const copyOfFilter = { ...filter };
    if (e.target.name === Tabs.RequesterOilRequests) {
      copyOfFilter.userIds = [user.id];
    } else {
      copyOfFilter.userIds = [];
    }

    this.setState(
      {
        tab: e.target.name,
        filter: copyOfFilter,
      },
      () => {
        onSearch(copyOfFilter);
      },
    );
  };

  getOptionValues = (optionName, currentValues) =>
    currentValues.map((value) =>
      options[optionName].find((option) => option.value === value),
    );

  render() {
    const { tab, filter } = this.state;

    return (
      <>
        <Accordion defaultActiveKey="filters">
          {/* <div className="navigation"> */}
          {/* <div className="navigation__view"> */}
          {/*   <div */}
          {/*     className={`navigation__view-item ${Tabs.AllOilRequests === tab ? "navigation__view-item--active" : ""}`} */}
          {/*   > */}
          {/*     <button */}
          {/*       type="button" */}
          {/*       className="btn navigation__view-button" */}
          {/*       name={Tabs.AllOilRequests} */}
          {/*       onClick={this.handleTabChange} */}
          {/*     > */}
          {/*       <img */}
          {/*         className="navigation__view-item-image" */}
          {/*         src="images/svg/globe.svg" */}
          {/*         alt="Kõik hanked" */}
          {/*       /> */}
          {/*     </button> */}
          {/*     <div className="navigation__view-item-text">Kõik hanked</div> */}
          {/*   </div> */}
          {/*   <div */}
          {/*     className={`navigation__view-item ${Tabs.RequesterOilRequests === tab ? "navigation__view-item--active" : ""}`} */}
          {/*   > */}
          {/*     <button */}
          {/*       type="button" */}
          {/*       className="btn navigation__view-button" */}
          {/*       name={Tabs.RequesterOilRequests} */}
          {/*       onClick={this.handleTabChange} */}
          {/*     > */}
          {/*       <img */}
          {/*         className="navigation__view-item-image" */}
          {/*         src="images/svg/project-management.svg" */}
          {/*         alt="Minu hanked" */}
          {/*       /> */}
          {/*     </button> */}
          {/*     <div className="navigation__view-item-text">Minu hanked</div> */}
          {/*   </div> */}
          {/* </div> */}
          {/* <div className="navigation__actions"> */}
          {/*   <CustomAccordionToggle eventKey="filters" callback={noop}> */}
          {/*     {/1* eslint-disable-next-line jsx-a11y/alt-text *1/} */}
          {/*     <img src="images/svg/filter.svg" /> */}
          {/*   </CustomAccordionToggle> */}
          {/* </div> */}
          {/* </div> */}
          {/* <hr /> */}
          <Accordion.Collapse eventKey="filters">
            <div className="filter">
              <div className="filter__item">
                <div className="form-group mb-2">
                  <label>Hanke staatus</label>
                  <Select
                    options={options.status}
                    onChange={this.handleFilterRequestStatusChange}
                    value={this.getOptionValues("status", filter.status)}
                    placeholder=""
                    isMulti
                  />
                </div>
                <div className="form-group mb-2">
                  <label>Kütused</label>
                  <Select
                    options={options.oil}
                    onChange={this.handleFilterOilChange}
                    value={this.getOptionValues("oil", filter.oil)}
                    placeholder=""
                    isMulti
                  />
                </div>
              </div>
              <div className="filter__item">
                <div className="form-group mb-2">
                  <label>Transport</label>
                  <div className="row">
                    <div className="col-sm-12">
                      <Select
                        options={options.transport}
                        onChange={this.handleFilterTransportChange}
                        value={this.getOptionValues(
                          "transport",
                          filter.transport,
                        )}
                        placeholder=""
                        isMulti
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label>Asukoht</label>
                  <div className="row">
                    <div className="col-sm-12">
                      <Select
                        options={options.address}
                        onChange={this.handleFilterAddressChange}
                        value={this.getOptionValues("address", filter.address)}
                        placeholder=""
                        isMulti
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter__item filter__search-container">
                <Button
                  type="button"
                  variant="light"
                  onClick={this.handleSearch}
                  className="filter__search"
                >
                  <img src="images/svg/magnifying-glass.svg" alt="search" />
                  Otsi
                </Button>
              </div>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </>
    );
  }
}

export default withRouter(OilRequestFilters);
