import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "../withRouter";
import ProtectedSidebar from "./sub/ProtectedSidebar";

function getTitle(path) {
  const reConversations = /\/conversations/g;
  if (reConversations.test(path)) {
    return "Sõnumid";
  }

  switch (path) {
    case "/oil-requests":
      return "Hanked";
    case "/oil-requests/create":
      return "Uus hange";
    case "/settings":
      return "Seaded";
    case "/":
      return "Avaleht";
    default:
      return "";
  }
}

type Props = {
  children: React.ReactNode;
} & RouteComponentProps<unknown>;

type State = {
  showSidebar: boolean;
  isMobile: boolean;
};

class Protected extends Component<Props, State> {
  private resizeTime = 0;

  constructor(props) {
    super(props);

    this.state = {
      showSidebar: false,
      isMobile: window.innerWidth < 1440,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  toggleSidebar = () => {
    if (this.state.isMobile) {
      this.setState((prevState) => ({
        showSidebar: !prevState.showSidebar,
      }));
    }
  };

  resize = () => {
    if (this.resizeTime) {
      clearTimeout(this.resizeTime);
    }
    this.resizeTime = window.setTimeout(() => {
      if (window.innerWidth < 1440 && !this.state.isMobile) {
        this.setState({ isMobile: true });
      } else if (window.innerWidth >= 1440 && this.state.isMobile) {
        this.setState({ isMobile: false });
      }
    }, 40);
  };

  render() {
    const { location } = this.props;

    return (
      <>
        {this.state.isMobile && (
          <div className="page-navigation">
            <button
              className="page-navigation__button"
              onClick={this.toggleSidebar}
            >
              <img src="/images/svg/menu.svg" alt="menu" />
            </button>
            <h4 className="page-navigation__title">
              {getTitle(location.pathname)}
            </h4>
          </div>
        )}
        <ProtectedSidebar
          isMobile={this.state.isMobile}
          show={this.state.showSidebar}
          pathname={location.pathname}
          onToggle={this.toggleSidebar}
        />
        <div
          className="page-content"
          style={{ paddingTop: this.state.isMobile ? "90px" : null }}
        >
          {this.props.children}
        </div>
      </>
    );
  }
}

export default withRouter(Protected);
