export function currencyFormat(num, precision = 2) {
  return `${num
    .toFixed(precision)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")} €`;
}

/**
 * Get number with spaces. E.g returs 1 000.
 *
 * @param {number} num
 * @return {string}
 */
export function numberWithSpaces(num) {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}
