import {
  OilRequestOilType,
  OilRequestTransportCode,
  OilRequestStatus,
  OilRequestOilUsageType,
  UserRole,
} from "../../utils/config";

/**
 * @param {string} oilCode
 * @return {string}
 */
export function getOilUsageTypeName(oilCode) {
  switch (oilCode) {
    case OilRequestOilUsageType.Wholesale:
      return "Hulgimüük";
    case OilRequestOilUsageType.Agriculture:
      return "Põllumajandus";
    case OilRequestOilUsageType.Fishing:
      return "Kalapüük";
    default:
      return "";
  }
}

/**
 * @param {string} oilType
 * @return {string}
 */
export function getOilTypeName(oilType) {
  switch (oilType) {
    case OilRequestOilType.Petrol95:
      return "95";
    case OilRequestOilType.Petrol98:
      return "98";
    case OilRequestOilType.Diesel:
      return "D";
    case OilRequestOilType.DieselSpecial:
      return "EDK";
    case OilRequestOilType.HydrotreatedVegetableOil:
      return "HVO";
    default:
      return "";
  }
}

/**
 * @param {string} oilType
 * @return {string}
 */
export function getOilTypeLongName(oilType) {
  switch (oilType) {
    case OilRequestOilType.Petrol95:
      return "Bensiin 95";
    case OilRequestOilType.Petrol98:
      return "Bensiin 98";
    case OilRequestOilType.Diesel:
      return "Diislikütus";
    case OilRequestOilType.DieselSpecial:
      return "Erimärgistusega diislikütus";
    case OilRequestOilType.HydrotreatedVegetableOil:
      return "HVO-biokütus";
    default:
      return "";
  }
}

/**
 * @param {number} oilRequestId
 * @param {number} [companyId]
 * @return {string}
 */
export function getOilRequestTitle(oilRequestId, companyId) {
  return `Hange #${oilRequestId} – ${companyId ? "Ettevõte" : "Eraisik"}`;
}

type getOilRequestTitleV2 = {
  id: string;
  user: {
    id: string;
    name: string;
  };
  company?: {
    id?: string;
    name?: string;
  };
};

export function getOilRequestTitleV2(oilRequest: getOilRequestTitleV2) {
  return `Hange #${oilRequest.id} – ${oilRequest.company?.id ? oilRequest.company.name : oilRequest.user.name}`;
}

/**
 * @param {string} transportCode
 * @return {string}
 */
export function getOilRequestTransportName(transportCode) {
  switch (transportCode) {
    case OilRequestTransportCode.Bidder:
      return "Võitja poolt";
    case OilRequestTransportCode.Requester:
      return "Hankija poolt";
    default:
      return "";
  }
}

/**
 * @param {string} status
 */
export function getOilRequestStatusColor(status) {
  switch (status) {
    case OilRequestStatus.Active:
      return "#388e3c";
    case OilRequestStatus.Transport:
      return "#512da8";
    case OilRequestStatus.Decision:
      return "#ffa000";
    default:
      return "#d32f2f";
  }
}

export function getOilRequestStatusColor2(status) {
  switch (status) {
    case OilRequestStatus.Active:
      return "success";
    case OilRequestStatus.Transport:
      return "primary";
    case OilRequestStatus.Decision:
      return "warning";
    default:
      return "danger";
  }
}

export function getOilRequestStatusName(status) {
  switch (status) {
    case OilRequestStatus.Active:
      return "Aktiivne";
    case OilRequestStatus.Transport:
      return "Teostamisel";
    case OilRequestStatus.Decision:
      return "Võitja valimine";
    default:
      return "Lõppenud";
  }
}

export function canCreateOrEditBid(user, oilRequest) {
  return (
    user.role === UserRole.Bidder &&
    oilRequest.user.id !== user.id &&
    oilRequest.status === OilRequestStatus.Active
  );
}

export function canChooseWinner(user, oilRequest) {
  return (
    user.id === oilRequest.user.id &&
    oilRequest.status === OilRequestStatus.Decision
  );
}

export function canCreateQuestion(user, oilRequest) {
  return (
    user.role === UserRole.Bidder &&
    oilRequest.user.id !== user.id &&
    oilRequest.status === OilRequestStatus.Active
  );
}

export function canAnswerQuestion(user, oilRequest) {
  return (
    user.id === oilRequest.user.id &&
    oilRequest.status === OilRequestStatus.Active
  );
}
