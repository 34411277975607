import { ThunkDispatch } from "redux-thunk";
import * as api from "../services/turgoil-api";
import { UserRole } from "../utils/config";
import { RootState } from "./store";

const USER_COMPANIES_LOADING = "user-companies/fetching";
const USER_COMPANIES_SUCCESS = "user-companies/success";
const USER_COMPANIES_RESET = "user-companies/logout";

export type UserCompanyState = {
  loading: boolean;
  companies: any[];
};

const initialState: UserCompanyState = {
  loading: false,
  companies: [],
};

export default function reducer(
  state = initialState,
  action: UserCompanySuccess | UserCompanyLoading | UserCompanyReset,
) {
  switch (action.type) {
    case USER_COMPANIES_RESET:
      return {
        companies: null,
        loading: false,
      };
    case USER_COMPANIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_COMPANIES_SUCCESS:
      return {
        companies: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

type UserCompanyLoading = {
  type: typeof USER_COMPANIES_LOADING;
};

type UserCompanySuccess = {
  type: typeof USER_COMPANIES_SUCCESS;
  payload: any;
};

type UserCompanyReset = {
  type: typeof USER_COMPANIES_RESET;
};

export function userCompaniesReset(): UserCompanyReset {
  return { type: USER_COMPANIES_RESET };
}

export function userCompaniesLoading(): UserCompanyLoading {
  return { type: USER_COMPANIES_LOADING };
}

export function userCompaniesSuccess(companies: any[]): UserCompanySuccess {
  return {
    type: USER_COMPANIES_SUCCESS,
    payload: companies,
  };
}

export function getUserCompanies(userId) {
  return async (
    dispatch: ThunkDispatch<
      RootState,
      null,
      UserCompanyLoading | UserCompanySuccess
    >,
  ): Promise<any> => {
    dispatch(userCompaniesLoading());
    return api.getUserCompanies(userId).then((res) => {
      dispatch(userCompaniesSuccess(res.data.companies));
      return res.data.companies;
    });
  };
}

export const selectUserDisplayName = (state: RootState): string | undefined => {
  const { user } = state.userReducer;
  if (
    [UserRole.Agent, UserRole.Requester, UserRole.Admin].includes(user.role)
  ) {
    return `${user.forename} ${user.surname}`;
  }

  const { companyId } = state.userReducer.user.bidderInfo || {};
  if (companyId) {
    const bidderCompany = state.userCompaniesReducer.companies.find(
      (c) => c.id === companyId,
    );
    return bidderCompany.companyName;
  }

  return "";
};
