import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../../common/Footer";

function Prices() {
  return (
    <>
      <Helmet>
        <title>Turgoil - Hinnakiri</title>
      </Helmet>
      <h3 className="information-page__title">Hinnakiri</h3>
      <p className="information-page__quick-answer">
        {" "}
        Alates 14.04.19 kehtib www.turgoil.com keskkonnas järgmine hinnakiri.
        Pakkujatele saadetakse kord kuus arve, mis sisaldab kuu aja jooksul
        võidetuid hankeid, arve tuleb tasuda TI-haldus OÜ arveldusarvele.
      </p>
      <p className="information-page__question">Kuidas arvutatakse arve?</p>
      <div className="information-page__quick-answer">
        Kui korraldatakse vedelkütuse hange siis liidetakse selles olev kütuse
        kogus kokku ning selle abil arvutatakse teenustasu. Teenustasu liitri
        kohta:
        <ul>
          <li>
            1 000 L - 1 999 L, mille teenustasu on 0.0014 € ühe liitri kohta
          </li>
          <li>
            2 000 L - 4 999 L, mille teenustasu on 0.0009 € ühe liitri kohta
          </li>
          <li>
            5 000 L - 9 999 L, mille teenustasu on 0.0007 € ühe liitri kohta
          </li>
          <li>
            10 000 L ja rohkem, mille teenustasu on 0.0003 € ühe liitri kohta
          </li>
        </ul>
        Seega kui korraldada vedelkütuse hange, mille summaarne kogus on näiteks
        5 000 L, siis hanke võitja peab selle eest maksma 3.5 € teenustasu. Kui
        hanke võitja võidab kuu jooksul 10 sellist hanget, siis on teenustasu 35
        €.
      </div>
      <p className="information-page__quick-answer">
        Teenustasu ei sisalda käibemaksu.
      </p>
      <p className="information-page__question">Tingimused</p>
      <p className="information-page__quick-answer">
        Teenustasu rakendub vaid pakkujatele, kes on hanke võitnud.
      </p>
    </>
  );
}

function FrequentlyAskedQuestions() {
  return (
    <>
      <Helmet>
        <title>Turgoil - Korduma kippuvad küsimused</title>
      </Helmet>
      <h3 className="information-page__title">Korduma kippuvad küsimused</h3>
      <h5 className="information-page__question">
        Palju hanke korraldamine maksab?
      </h5>
      <p className="information-page__quick-answer">
        Hanke korraldamine on tasuta!
      </p>
      <h5 className="information-page__question">
        Palju hankele pakkumiste tegemine maksab?
      </h5>
      <p className="information-page__quick-answer">
        Pakkumiste tegemine on tasuta!
      </p>
      <h5 className="information-page__question">
        Mis on "vedelkütuse hange"?
      </h5>
      <p className="information-page__quick-answer">
        Kui Te soovite osta vedelkütust alates 1 000 L ja soovite saada
        hinnapakkumisi, siis on tegemist hankega.
      </p>
      <h5 className="information-page__question">
        Kui minu hankele ei tehta ühtegi mulle sobivat pakkumist, kas ma pean
        siiski võitja valima?
      </h5>
      <p className="information-page__quick-answer">
        Ei pea, kuid see on rangelt soovituslik. Kui hankele võitjat ei ole
        valitud, ootame hanke korraldajalt põhjendust. Selle saab postitada enda
        hankesse peale võitja valiku etappi lõppu.
      </p>
      <h5 className="information-page__question">
        Kui olen hanke lisanud, siis kas saan hanget hiljem muuta?
      </h5>
      <p className="information-page__quick-answer">
        Ei saa. Muudatused pole lubatud. Kui midagi peaks hanke lisamisel
        valesti minema siis vastavatest muudatustest palume teavitada
        info@turgoil.com.
      </p>
      <h5 className="information-page__question">
        Kas ma saan teha vedelkütuse hanke, mille kogus jääb alla 1 000 L?
      </h5>
      <p className="information-page__quick-answer">
        Ei, kahjuks ei saa. Keskkond võimaldab ainult korraldada hankeid, mille
        kogus on rohkem kui 1 000 L.
      </p>
      <h5 className="information-page__question">
        Mis siis juhtub kui ma rikun turgoil.com reegleid?
      </h5>
      <p className="information-page__quick-answer">
        Oleneb rikkumise suurusest. Reeglina kasutajakonto blokeeritakse.
      </p>
      <h5 className="information-page__question">
        Mis mõte sellel lehel üldse on? Mis mulle sellest kasu?
      </h5>
      <p className="information-page__quick-answer">
        Kui sa soovid osta vedelkütust suurtes kogustes ja soovid turu parimat
        hinda ja ei soovi helistada mitmetele kütuse ärimeestele. Korraldades
        vedelkütuse hanke siin, teevad kütuse ärimehed sulle ise pakkumisi ning
        lõpuks valid endale sobiva hinna. Säästad sellega aega, raha ja ka
        närve.
      </p>
      Kõik küsimused ja ettepanekud palume saata info@turgoil.com
    </>
  );
}

function Instructions() {
  return (
    <>
      <Helmet>
        <title>Turgoil - Kasutusjuhend</title>
      </Helmet>
      <h3 className="information-page__title">Kasutusjuhend</h3>
      <div className="information-page__content">
        <h5 className="information-page__question">Kuidas liituda?</h5>
        <p className="information-page__quick-answer">
          Liituda saavad nii juriidilised kui eraisikud ning see on tasuta.
        </p>
        <div className="information-page__long-answer">
          <ol className="information-page__long-answer-list">
            <li className="information-page__long-answer-list-item">
              Liitumiseks peate kõigepealt vajutama lingile "Loo kasutaja".
              Lisaks on võimalus kohe liituda juhul kui omate Facebook või
              Google kontot.
            </li>
            <li className="information-page__long-answer-list-item">
              Teile avaneb liitumise vorm, mille peate ära täitma. Kui kõik
              väljad on täidetud, siis vajuta "Registreeru".
            </li>
            <li className="information-page__long-answer-list-item">
              Kui täitsite kõik väljad korrektselt Teile ei kuvatud ühtegi
              veateadet, siis on Teile e-posti aadressile saadetud link, millele
              vajutades on konto aktiveeritud. Kui olete oma konto aktiveerinud,
              siis saate sisse logida.
            </li>
          </ol>
        </div>
        <h5 className="information-page__question">
          Kuidas vedelkütuse hanget lisada?
        </h5>
        <p className="information-page__quick-answer">
          Hanget saavad lisada nii juriidilised kui eraisikud ning see on
          tasuta.
        </p>
        <div className="information-page__long-answer">
          <ol className="information-page__long-answer-list">
            <li className="information-page__long-answer-list-item">
              Logige oma kasutajaga sisse.
            </li>
            <li className="information-page__long-answer-list-item">
              Kui soovite korraldada hanget eraisikuna, siis valige vasakult
              menüüst "Uus hange" ning vajutage sellele. Juhul kui soovite
              korraldada hanget juriidilise isikuna ning Teil ei ole ühtegi
              ühtegi ettevõtet, siis vaadake juhendit kuidas ettevõte lisada.
            </li>
            <li className="information-page__long-answer-list-item">
              Sulle avanes hanke lisamise vorm. Täida ära võimalikult paljud
              väljad, et pakkujatel oleks parem ülevaade sinu hankest ning
              vajuta "Korralda hange".
            </li>
          </ol>
        </div>
        <h5 className="information-page__question">
          Kuidas vedelkütuse hanget korraldada?
        </h5>
        <p className="information-page__quick-answer">
          Peale hanke lisamist on hange aktiivne ning pakkujad saavad Teile
          pakkumisi teha.
        </p>
        <div className="information-page__long-answer">
          <ol className="information-page__long-answer-list">
            <li className="information-page__long-answer-list-item">
              Hange on olekus "aktiivne" - See on pakkumiste tegemise etapp, kus
              pakkujad saavad Teie hankele pakkumisi teha ja Teilt küsimusi
              küsida. Antud etapp kestab nii kaua nagu Te hanke lisamisel hanke
              kestvuseks märkisite.
            </li>
            <li className="information-page__long-answer-list-item">
              Hange on olekus "võitja valimine" - See on võitja valimise etapp,
              mis algab peale pakkumiste tegemise etappi. Nüüd ei saa firmad
              teile enam pakkumisi teha ega Teilt lisaküsimusi küsida. Selles
              etapis selgitate Te välja hanke võitja ja kinnitate oma valikut
              klikkides vastava pakkuja järel oleval nupul. Kui Teie hange
              lõppes ja Teile ei tehtud ühtegi pakkumist, siis hange läheb
              automaatselt olekusse "lõppenud".
            </li>
            <li className="information-page__long-answer-list-item">
              Hange on olekus "teostamisel" - See on etapp, kus hankija ja
              pakkuja näevad teineteise kontaktandmeid ning võtavad üksteisega
              ühendust, et täpsustada üle tarnetähtaeg ja sellega seotud
              mahalaadimise / peale laadimise detailid.
            </li>
            <li className="information-page__long-answer-list-item">
              Hange on olekus "lõppenud" - See on viimane etapp ning sellesse
              etappi saab mitmel juhul. a) Hange on olekus "teostamisel" ja
              ületab korraldaja poolt valitud tarnetähtaja, see tähendab seda,
              et hankija on oma kütuse kätte saanud. b) Hange on olekus "võitja
              valimine" ja hankija ei jõudunud või unustas hanke võitja valida.
              c) Hange on olekus "aktiivne" ning sellele ei tehtud ühtegi
              pakkumist.
            </li>
            <li className="information-page__long-answer-list-item">
              NB: Kui hanke korraldaja ei vali enda hankele võitjat peab ta enda
              hankesse postitama põhjuse miks ta seda ei teinud. Põhjenduse saab
              lisada alles peale võitja valiku etappi lõppu ning siis saadetakse
              kliendile ka vastav õpetus emaili.
            </li>
          </ol>
        </div>
        <h5 className="information-page__question">Kuidas pakkumisi teha?</h5>
        <p className="information-page__quick-answer">
          Hangetele saavad pakkumisi teha vaid ettevõtted, kellel on tegevusluba
          Eestis ning on TI-haldus OÜ poolt heaks kiidetud. Pakkumiste tegemine
          on tasuta.
        </p>
        <div className="information-page__long-answer">
          <ol className="information-page__long-answer-list">
            <li className="information-page__long-answer-list-item">
              Pakkumise tegemiseks peab pakkuja enda kasutajanimega turgoil.com
              keskkonda sisse logima.
            </li>
            <li className="information-page__long-answer-list-item">
              Igas hankes on pakkumiste tegemise väli kuhu saab postitada enda
              pakkumise. Pakkumist saab muuta seni kuni hange on aktiivne.
            </li>
          </ol>
        </div>
        <h5 className="information-page__question">Kuidas võitjat valida?</h5>
        <p className="information-page__quick-answer">
          Võitjat saab valida siis kui hange on võitja valimise etapis (võitja
          valimise etapp kestab 4 tundi peale hanke lõppu), kui kahe tunni
          jooksul võitjat ei valita, siis kuulutatakse hange lõppenuks ning
          korraldaja peab kirjutama, miks ta võitjat ei valinud.
        </p>
        <div className="information-page__long-answer">
          <ol className="information-page__long-answer-list">
            <li className="information-page__long-answer-list-item">
              Võitja valimiseks peab hanke korraldaja sisse logima, enda hanke
              avama ja vastava pakkumise taga nupule vajutama.
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}

function Advertisement() {
  return (
    <>
      <Helmet>
        <title>Turgoil - Reklaam</title>
        <meta
          name="description"
          content=" Turgoil.com alustas oma tegevust aastal 2019 ning tegeleb vedelkütuse hangete vahendamisega. Meie peamiseks külastajaks on inimene, kes soovib osta endale vedelkütust suurtes kogustes."
        />
      </Helmet>
      <h3 className="information-page__title">Reklaam</h3>
      <p className="information-page__quick-answer">
        Turgoil.com alustas oma tegevust aastal 2019 ning tegeleb vedelkütuse
        hangete vahendamisega. Meie peamiseks külastajaks on inimene, kes soovib
        osta endale vedelkütust suurtes kogustes. Hetkel on meie keskkond
        reklaamivaba. Kui soovite, et siin veebilehel näidatakse Teie reklaami,
        siis palun kirjutage meile ja tehke pakkumine info@turgoil.com.
      </p>
    </>
  );
}

function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <title>Turgoil - Kasutustingimused</title>
      </Helmet>
      <h3 className="information-page__title">Kasutustingimused</h3>
      <p>
        Registreerudes internetilehekülje www.turgoil.com kasutajaks, kinnitad,
        et oled tutvunud käesoleva kasutajalepingu tingimustega (edaspidi
        Kasutustingimused), nõustud Kasutustingimustega ning kohustud
        Kasutustingimusi täitma alljärgnevas:
      </p>
      <div className="information-page__terms">
        <ol>
          <li>
            ÜLDSÄTTED
            <ol>
              <li>
                Käesolevad Kasutustingimused reguleerivad õigussuhteid, mis
                tekivad turgoil.com keskkonna kasutamisel.
              </li>
              <li>
                Kasutustingimustes kasutatakse mõisteid alljärgnevas tähenduses:
                <ol>
                  <li>
                    Turgoil.com - internetiaadressil www.turgoil.com asuv
                    teenuste osutamise keskkond;
                  </li>
                  <li>
                    Kasutajakonto - turgoil.com kasutajaks registreeritud isiku
                    kasutajatunnus koos salasõnaga;
                  </li>
                  <li>Kasutaja - isik, kellel on turgoil.com kasutajakonto;</li>
                  <li>
                    Hanke korraldaja – kasutaja, kes on turgoil.com keskkonda
                    lisanud hanke;
                  </li>
                  <li>
                    Pakkuja – kasutaja, kes on teinud pakkumise turgoil.com
                    keskkonnas olevale hankele;
                  </li>
                  <li>
                    Hanke võitja – kasutaja, kes on Hanke korraldaja poolt
                    määratud hanke võitjaks;
                  </li>
                  <li>
                    Hanke luhtumine – situatsioon, kus peale Hanke võitja
                    valikut Hanke korraldaja ja Hanke võitja ei saavuta omavahel
                    kokkulepet ning hange jääb teostamata;
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            KASUTAJALEPINGU SISU
            <ol>
              <li>
                TI-haldus OÜ poolt turgoil.com keskkonnas osutatava teenuse
                sisuks on Kasutajale hanke kuulutuse avaldamise ja/või pakkumise
                tegemise võimaluse loomine Kasutustingimustes sätestatud
                tingimustel. TI-haldus OÜ ei tegele vedelkütuse müügiga, ega ole
                lepingu pooleks õigussuhtes, mis tekib Hanke korraldaja ja Hanke
                võitja vahel.
              </li>
              <li>
                Turgoil.com keskkonnas võib avaldada üksnes selliseid kuulutusi
                ja informatsiooni, mis pole seaduse või Kasutajatingimustega
                keelatud.
              </li>
            </ol>
          </li>
          <li>
            TURGOIL.COM ÕIGUSED JA KOHUSTUSED
            <ol>
              <li>
                Turgoil.com-l on õigus:
                <ol>
                  <li>
                    kontrollida, et keskkonna kasutamine toimuks kooskõlas
                    Kasutustingimustega. Rikkumise avastamisel võib turgoil.com
                    kasutada kõiki seaduses ja käesolevates Kasutustingimustes
                    sätestatud õigusi.
                  </li>
                  <li>
                    nõuda Kasutajakonto loomisel isiku autentimist ja töödelda
                    teenuste osutamise eesmärgil isikuandmeid seaduses
                    sätestatud tingimustel ja korras;
                  </li>
                  <li>
                    tutvuda talle sobival ajal Kasutaja poolt avalikkusele
                    kättesaadavaks tehtud materjalidega kontrollimaks, kas
                    nimetatud materjalid pole vastuolus Kasutustingimustes
                    sätestatuga.
                  </li>
                  <li>
                    kuvada pakkujatele hanke korraldaja andmeid (eesnimi ja
                    perenimi) juhul kui on tegemist eraisiku hankega;
                  </li>
                  <li>
                    saata Hanke võitjale andmed (kontaktandmed) Hanke korraldaja
                    kohta;
                  </li>
                  <li>
                    saata Hanke korraldajale andmed (kontaktandmed) Hanke võitja
                    kohta;
                  </li>
                  <li>
                    küsida Hanke korraldajalt ja/või Hanke võitjalt põhjendusi
                    selle kohta, miks jäi teostamata kuulutuses kirjeldatud
                    hange.
                  </li>
                  <li>
                    blokeerida seaduse ja Kasutustingimuste vastaselt käituva
                    Kasutaja Kasutajakonto;
                  </li>
                  <li>
                    küsida Kasutajalt Kasutajakonto blokeerimise lõpetamise eest
                    tasu;
                  </li>
                  <li>
                    arvestada maha hanke tasu Kasutustingimustes toodud
                    tingimustel üksnes juhul, kui hange on tunnistatud luhtunuks
                    objektiivsel põhjusel;
                  </li>
                  <li>
                    tingimusteta ja viivitamatult peatada teenuste kasutamise
                    võimalus (Kasutaja blokeerida) juhul, kui Kasutaja eirab
                    Kasutustingimusi või turgoil.com-l on põhjendatud kahtlus,
                    et Kasutaja võib neid eirata;
                  </li>
                </ol>
              </li>
              <li>
                Turgoil.com-l on kohustus:
                <ol>
                  <li>
                    hooldada ning jälgida teenuste toimimist ja nende Kasutajale
                    kättesaadavust;
                  </li>
                  <li>
                    tagada Kasutaja abistamiseks klienditoe toimimine e-posti
                    aadressil info@turgoil.com;
                  </li>
                  <li>esitada Hanke võitjale andmed Hanke korraldaja kohta;</li>
                  <li>esitada Hanke korraldajale andmed Hanke võitja kohta;</li>
                  <li>blokeerida Kasutaja avalduse alusel Kasutajakonto.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            PIIRANGUD TURGOIL.COM TEENUSTE KASUTAMISEL
            <ol>
              <li>
                turgoil.com teenuste kasutamine Eesti Vabariigi seadusandluse
                ning heade tavade ja kommetega vastuolus oleva, sündsusetu või
                muul viisil sobimatu teo tegemiseks või selle propageerimiseks
                on keelatud.
              </li>
              <li>
                Sealhulgas, kuid mitte ainult, on turgoil.com teenuste
                kasutamisel keelatud alljärgnevalt loetletud teod:
                <ol>
                  <li>
                    juurdepääsu tekitamine internetivõrgus olevatele sellistele
                    ressurssidele, millele juurdepääs ei ole Kasutajale lubatud;
                  </li>
                  <li>
                    autoriõigusega kaitstavate teoste, kaubamärkide, ärinime,
                    reklaami, litsentside, tööstusdisaini vms
                    intellektuaalomandi kasutamine, mille kasutamine ei ole
                    antud Kasutajal lubatud, või viisil, kuidas ei ole lubatud;
                  </li>
                  <li>
                    arvutitehnika abil koostatud või kogutud andmete
                    terviklikkuse omavoliline hävitamine, kahjustamine või
                    muutmine;
                  </li>
                  <li>
                    selliste andmete ja materjalide kopeerimine või mistahes
                    viisil edastamine, mis tekitavad teenusserveritele
                    ebanormaalset koormust;
                  </li>
                  <li>
                    arvutiviiruste või programmide, mis sisaldavad endas viirusi
                    või mistahes muid koode, faile või programme ja/või selle
                    osasid, mis on loodud segama, häirima või piirama
                    telekommunikatsioonivõrgu, selle seadmete või kasutatava
                    tarkvara funktsionaalsust, mistahes viisil levitamine;
                  </li>
                  <li>
                    julmuse, füüsilise või psüühilise vägivalla ning
                    ebaseadusliku tegevuse propageerimine;
                  </li>
                  <li>
                    kas otseselt või kaudselt teistele Internetivõrgus olevatele
                    materjalidele viitamine, Kasutaja enda või mõne muu isiku
                    reklaamimine, välja arvatud juhul, kui see on lubatud
                    käesolevates Kasutustingimustes;
                  </li>
                  <li>
                    turgoil.com teenuseid kasutades Internetivõrgu töö või
                    teiste Kasutajate häirimine;
                  </li>
                  <li>
                    turgoil.com keskkonda libapakkumiste ja/või libahangete
                    sisestamine;
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            KASUTAJA ÕIGUSED JA KOHUSTUSED
            <ol>
              <li>
                Kasutaja kohustub esitama turgoil.com kasutajaks
                registreerumisel tõeseid isikuandmeid, sh registreeruma oma õige
                ja täieliku nime all ning kasutama kehtivat e-posti aadressi.
              </li>
              <li>
                Kasutaja kohustub mitte kasutama turgoil.com keskkonda
                ebaseaduslikeks tehingu(te)ks ja/või pettus(t)eks.
              </li>
              <li>
                Hanke korraldaja kohustub turgoil.com-le andma 5 (viie) päeva
                jooksul tagasisidet kõikidest põhjustest, miks Hanke korraldaja
                arvates ei ole võimalik Hanke võitjaga saavutada kokkulepet
                Hanke korraldaja poolt kuulutuses avaldatud töö teostamiseks,
                kui turgoil.com temaga kontakteerub kas e-posti või telefoni
                teel.
              </li>
            </ol>
          </li>
          <li>
            HANKE LUHTUMINE
            <ol>
              <li>
                Hanke võitja võib esitada turgoil.com e-postile info@turgoil.com
                avalduse hanke luhtunuks tunnistamiseks, kui Hanke võitja ei ole
                Hanke korraldajaga saavutanud 5 (viie) päeva jooksul kokkulepet,
                mille esemeks on turgoil.com-s avaldatud hange. Turgoil.com-le
                esitatavas avalduses tuleb Hanke võitjal esitada kõik Hanke
                luhtumise põhjused.
              </li>
              <li>
                Hanke luhtunuks tunnistamise menetluses kontrollib turgoil.com
                Hanke võitja poolt hanke luhtumise kohta esitatud põhjendusi ja
                teeb nende põhjal otsuse. Turgoil.com-l on õigus küsida Hanke
                võitjalt täiendavat informatsiooni hanke luhtunuks tunnistamise
                avalduses esitatud põhjenduste kohta.
              </li>
              <li>
                Hanke luhtunuks tunnistamisel sisaldub hange Pakkuja arves juhul
                kui:
                <ol>
                  <li>
                    Pärast võitja välja kuulutamist ei ole Hanke võitja Hanke
                    korraldajaga kontakteerunud 5 (viie) päeva jooksul.
                  </li>
                  <li>
                    Hanke luhtumise põhjuseks ei ole objektiivne asjaolu.
                    Objektiivse asjaoluna käsitleb turgoil.com sellist asjaolu,
                    mida Hanke võitja ja/või Hanke korraldaja ei saanud oma
                    käitumisega mõjutada;
                  </li>
                  <li>
                    Hanke võitja ei ole esitanud - turgoil.com-le hanke
                    luhtunuks tunnistamise kohta täiendavat informatsiooni 5
                    (viie) päeva jooksul arvates turgoil.com poolt vastava kirja
                    saatmisest Hanke võitja e-postile.
                  </li>
                  <li>
                    Hanke võitja poolt esitatud pakkumine on turgoil.com
                    reeglitega vastuolus.
                  </li>
                </ol>
              </li>
              <li>
                Turgoil.com teavitab Hanke võitjat kirjalikult e-posti teel
                Hanke luhtunuks tunnistamisest juhul kui hange on luhtunud siis
                see ei kajastu arves, kusjuures otsus on lõplik.
              </li>
              <li>
                Hanke luhtunuks tunnistamisel seoses objektiivse asjaolu
                esinemisega ei kajastu see arve arves.
              </li>
            </ol>
          </li>
          <li>
            VASTUTUS
            <ol>
              <li>
                turgoil.com vastutab Kasutustingimustes nimetatud kohustuste
                täitmise eest antud ajahetkel parimal võimalikul viisil,
                kusjuures turgoil.com ei võta endale mistahes vastutust seoses
                Kasutaja võimalike kahjudega alljärgnevatel juhtudel:
                <ol>
                  <li>
                    kui kahju on tekkinud - turgoil.com-st mitteolenevatel
                    põhjustel, eelkõige häiretest teenuste osutamisel või
                    teenuste kasutamise eeldusena toimivas internetiühenduses;
                  </li>
                  <li>
                    kui kahju on tekkinud - turgoil.com-st mitteolenevatel
                    põhjustel kasutaja informatsiooni edastamisviivituse või
                    mahakustutamise tulemusena;
                  </li>
                  <li>
                    kui kahju on tekkinud volitamata isikute juurdepääsu
                    tulemusena Internetivõrgus asuvale informatsioonile või
                    sellise informatsiooni muutmisel selleks volitamata isikute
                    poolt;
                  </li>
                  <li>
                    kui Kasutajal on pretensioone teenuste kaudu või nendega
                    seoses esitatava või saadava informatsiooni, teenuste või
                    toodete kättesaadavuse, oma eesmärkidele sobivuse, nende
                    tõesuse ja/või sisu suhtes.
                  </li>
                </ol>
              </li>
              <li>
                Kasutaja vastutab muuhulgas iseseisvalt ja täielikult:
                <ol>
                  <li>
                    tema poolt teenuste kasutamisel edastatavate andmete ja
                    informatsiooni sisu eest ning nende vastavuse eest
                    Kasutustingimustes ja kehtivas seadusandluses sätestatuga;
                  </li>
                  <li>teenuste kasutamise eesmärkide kuritarvitamise eest;</li>
                  <li>
                    kahjude eest, mis tekivad Kasutajale endale ja/või mistahes
                    kolmandale isikule seoses käesolevate Kasutustingimuste
                    rikkumisega Kasutaja poolt.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            INTELLEKTUAALOMAND
            <ol>
              <li>
                Platvormi omandiõigus
                <ol>
                  <li>
                    Turgoil ja tema litsentsiandjad säilitavad kõik
                    intellektuaalomandi õigused platvormile ja selle sisule.
                  </li>
                  <li>
                    Ilma, et see piiraks käesolevate tingimuste konkreetsemaid
                    sätteid, kuuluvad sinna intellektuaalomandi õigused,
                    andmebaasiõigused, disainiomandid, kataloogimise õigused,
                    kaubamärgiomandid, patendiõigused. Sealhulgas kuuluvad
                    ainuõiguslikult Turgoilie või tema litsentsiandjatele
                    algoritmid, kood, kontseptsioon (sh funktsioonide ja
                    elementide struktuur), andmed, disain, kirjeldused,
                    joonised, võrrandid, valemid, graafika, know-how, märgid,
                    objektid, objektikood, pildid, fotod, protsessid, esitlused,
                    kviitungid, skeemid, lähtekood, tehnilised lahendused.
                    Kasutajad ei tohi kopeerida, muuta ega levitada ühtegi osa
                    platvormist või selle sisust ilma Turgoili kirjaliku loata.
                  </li>
                  <li>
                    Kõik kaubamärgid, nominaalsed või kujundlikud ja kõik muud
                    märgid sh. kaubanimetused, teenusmärgid, sõnamärgid,
                    illustratsioonid, pildid või logod, mis ilmuvad seoses selle
                    platvormiga, on ja jäävad ainuõiguslikult Turgoili või tema
                    litsentsiandjate omandiks ning alluvad intellektuaalomandi
                    kohta kehtivatele seadustele või rahvusvahelistele lepetele.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            KASUTAMISTINGIMUSTE KEHTIVUS JA MUUTMINE
            <ol>
              <li>
                Kasutustingimused jõustuvad turgoil.com keskkonda Kasutajakonto
                loomisest alates ja kehtivad tähtajatult.
              </li>
              <li>
                Turgoil.com-l on õigus ilma Kasutajale ette teatamata muuta
                ja/või modifitseerida teenuste sisu, parameetreid, piiranguid,
                protseduure, teenuste osutamise menüüstruktuure,
                dokumentatsiooni jms. Muudatused loetakse kasutajale teatavaks
                tehtuks alates nende paigutamise hetkest turgoil.com keskkonda.
              </li>
              <li>
                Turgoil.com-l on õigus teenuste arengust tulenevalt ning nende
                parema ja turvalisema kasutamise huvides Kasutustingimusi ja
                hinnakirja ühepoolselt muuta ning täiendada.
              </li>
              <li>
                Turgoil.com võib teavitada Kasutajat Kasutustingimuste ja
                hinnakirja muudatustest ja täiendustest www.turgoil.com-s
                avaldatavate vastavate teadete kaudu.
              </li>
              <li>
                Kasutustingimuste ja hinnakirja muudatused ning täiendused
                jõustuvad vastava muudatuse või täienduse avaldamisest
                www.turgoil.com.
              </li>
              <li>
                Kasutaja kinnitab oma nõustumisest muudatuste ja täiendustega
                hinnakirjas ja/või Kasutustingimustes turgoil.com teenuste
                kasutamisega peale vastavate muudatuste/täienduste jõustumist.
              </li>
            </ol>
          </li>
          <li>
            LÕPPSÄTTED
            <ol>
              <li>
                Turgoil.com kasutamisest tekkinud vaidlusi püüavad Kasutaja ja
                turgoil.com lahendada läbirääkimiste teel. Kokkuleppe
                mittesaavutamisel lahendab vaidluse Tartu Maakohus.
              </li>
              <li>
                Kasutustingimused kehtivad nende aktsepteerimisel kasutaja poolt
                ja kehtivad Kasutaja ja turgoil.com vaheliste Kasutustingimustes
                reguleeritud õigussuhete kehtivuse ajal. Kasutajal igal ajal
                õigus turgoil.com kasutajaleping üles öelda, täites eelnevalt
                temal lasuvad kehtivad kohustused turgoil.com ja teiste
                Kasutajate ees.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </>
  );
}

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Turgoil - Privaatsustingimused</title>
      </Helmet>
      <h3 className="information-page__title">Privaatsustingimused</h3>
      <p>
        <span>Kehtiv alates 01.09.2024</span>
      </p>
      <p>
        <span>
          Privaatsustingimused reguleerivad isikuandmete töötlemist ja küpsiste
          kasutamist TI-haldus OÜ veebilehel turgoil.com (edaspidi veebileht).
          Privaatsustingimused on kooskõlas andmekaitse üldregulatsiooniga (GDPR
          – General Data Protection Regulation).
        </span>
      </p>
      <p>
        <span>
          Privaatsustingimuste eesmärk on kaitsta klientide privaatsust
          kooskõlas Eesti Vabariigi seaduste ning Euroopa Liidu õigusaktidega.
        </span>
      </p>
      <p>
        <span>
          Kasutades Turgoil`i veebilehte nõustute küpsiste kasutamisega ja
          isikuandmete töötlemise tingimustega nagu on kirjeldatud veebilehe
          privaatsustingimustes.
        </span>
      </p>
      <p>
        <span>
          Enne meie poliitika lugemist jätkamist soovime veenduda, et mõistate
          selle dokumendis kasutatavat terminoloogiat. Palume Teil tutvuda nende
          definitsioonidega enne selle privaatsuspoliitika lugemisE jätkamist.
          Nende definitsioonide mainimine ei tähenda ühegi sellise andme
          kasutamist, kogumist või töötlemist, mida ei ole selgesõnaliselt
          mainitud selle privaatsuspoliitika vastavas osas.
        </span>
      </p>
      <h4>Mõisted</h4>
      <h5>Isikuandmed (või andmed)</h5>
      <p>
        <span>
          Isikuandmeteks loetakse kogu teavet, mis otse või kaudselt, üksinda
          või koos teiste tüüpi teabega võimaldab tuvastada või saada
          tuvastatavaks füüsilise isikuna. See tähendab, et isikuandmeteks võib
          lugeda mitmesugust teavet, nagu telefoninumbrid ja e-posti aadressid.
        </span>
      </p>
      <h5>Andmesubjekt</h5>
      <p>
        <span>Füüsiline isik, kellele isikuandmed viitavad.</span>
      </p>
      <h5>Andmetöötleja</h5>
      <p>
        <span>
          Füüsiline või juriidiline isik, avalik võimuorgan, ametiasutus,
          agentuur või muu organ, kes töötleb isikuandmeid vastutaja nimel, nagu
          kirjeldatud selles privaatsuspoliitikas. Lisateavet töötleja kohta
          leiate vastavas osas.
        </span>
      </p>
      <h5>Vastutaja</h5>
      <p>
        <span>
          Füüsiline või juriidiline isik, avalik võimuorgan, ametiasutus,
          agentuur või muu organ, kes üksinda või koos teistega määrab
          isikuandmete töötlemise eesmärgid ja vahendid, sealhulgas selle
          veebisaidi toimimise ja kasutamisega seotud turvameetmed. Vastutaja,
          kui ei ole teisiti sätestatud, on Turgoil`l.
        </span>
      </p>
      <p>
        <h5>Euroopa Liit ja Euroopa Majanduspiirkond (EL/EMP)</h5>
      </p>
      <p>
        <span>
          Kui ei ole teisiti sätestatud, hõlmavad kõik selle dokumendis Euroopa
          Liidule viidatud viited kõiki Euroopa Liidu ja Euroopa
          Majanduspiirkonna praeguseid liikmesriike.
        </span>
      </p>
      <p>
        <strong>Kasutajad</strong>
        <span>
          &nbsp;on kõik isikud, kes külastavad veebilehte, tarbivad teenuseid
          või edastavad Turgoil`ile omalt poolt infot.
        </span>
      </p>
      <p>
        <strong>Teenused</strong>
        <span>&nbsp;on kõik Turgoil`i poolt pakutavad tooted ja teenused.</span>
      </p>
      <p>
        <h4>Isikuandmete töötlemine</h4>
      </p>
      <p>
        <span>
          Isikuandmeid kogutakse järgmistel eesmärkidel ja järgmiste teenuste
          kasutamiseks:
        </span>
      </p>
      <ol>
        <li className="mb-3">
          <h5>
            Üksikisikute või organisatsioonide vaheline suhtlus, sealhulgas
            e-post ja telefonikõned.
          </h5>
          <p>
            <span>
              E-posti ja suhtluse eesmärgil töödeldavad isikuandmed võivad
              sisaldada:{" "}
            </span>
          </p>
          <ul>
            <li>
              <span>
                Kontaktandmeid: see hõlmab nimesid, e-posti aadresse,
                telefoninumbreid ja muid asjakohaseid kontaktandmeid, mis on
                vajalikud suhtluse alustamiseks ja säilitamiseks.{" "}
              </span>
            </li>
            <li>
              <span>
                Suhtluse sisu: see viitab saadetud sõnumite tegelikule sisule,
                nagu e-posti tekst, manused, pildid või muu suhtluse ajal
                jagatud meedia.
              </span>
            </li>
            <li>
              <span>
                Suhtluse ajalugu: andmeid kliendi suhtluse kohta, sealhulgas
                müügipäringuid, toeteateid ja kirjavahetust, võib töödelda
                kliendi kaasatuse jälgimiseks ja isikliku abi osutamiseks.{" "}
              </span>
            </li>
            <li>
              <span>
                Metaandmed: võidakse töödelda suhtlusega seotud metaandmeid,
                sealhulgas ajatemplid, saatja ja adressaadi teave, IP-aadressid
                ja muud tehnilised andmed, mis aitavad suhtlusprotsessi hallata
                ja jälgida.
              </span>
            </li>
          </ul>
          <p>
            <span>Andmete töötlemise õiguslik alus</span>
            <span>
              : e-posti ja suhtluse eesmärgil isikuandmete töötlemise õiguslik
              alus võib varieeruda sõltuvalt konkreetsetest olukordadest ja
              kohaldatavatest seadustest.{" "}
            </span>
          </p>
          <p>
            <span>Üldised õiguslikud alused hõlmavad: </span>
          </p>
          <ul>
            <li>
              <span>
                Nõusolek: potentsiaalsete klientide või kasutajate puhul, kes
                võtavad ettevõttega ühendust hinna ja turunduse kohta. Isik on
                andnud oma selgesõnalise nõusoleku neile e-kirjade saatmiseks
                või nendega suhtlemiseks. Seega võib töötlemine põhineda nende
                nõusolekul. Selle võib tühistada, saates taotluse
                kontakt-e-posti aadressile. Kui asjakohane, palub Turgoil
                klientidelt nõusolekut kõne salvestamiseks, nõusoleku keeldumine
                ei mõjuta kuidagi kliendile pakutavat teenust.
              </span>
            </li>
            <li>
              <span>
                Lepingu vajadus: kui suhtlus on vajalik lepingu täitmiseks või
                andmesubjekti taotlusel leppetegevuslike sammude astumiseks,
                võib töötlemine põhineda lepingu vajadusele. See on seotud
                suhtlusega klientidega.
              </span>
            </li>
            <li>
              <span>
                Õigustatud huvid: töötlemine võib olla õigustatud selle
                õigusliku aluse alusel, näiteks juhul, kui suhtlus on peetud
                oluliseks või seadusest nõutavaks andmesubjektiga.
              </span>
            </li>
          </ul>
        </li>
        <li className="mb-3">
          <h5 className="d-inline">
            <span>
              Turundussuhtluse hõlbustamine individuaalide või
              organisatsioonidega
            </span>
          </h5>
          <span>
            . See hõlmab reklaammaterjalide, värskenduste, pakkumiste ja muude
            turundussõnumite saatmist toodete, teenuste või ürituste
            reklaamimiseks.
          </span>
          <p>
            <span>
              Turundussuhtluse eesmärgil töödeldavad isikuandmed võivad
              sisaldada:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Kontaktandmed: see hõlmab nimesid, e-posti aadresse,
                telefoninumbreid, ametikohti, postiaadresse ja muid asjakohaseid
                kontaktandmeid, mis on vajalikud turundussuhtluse edastamiseks.
              </span>
            </li>
            <li>
              <span>
                Demograafilised andmed: võidakse töödelda täiendavaid
                demograafilisi andmeid, nagu asukoht, eelistused ja ostuajalugu,
                et isikustada turundussõnumeid ja kohandada neid konkreetsetele
                sihtgruppidele.
              </span>
            </li>
            <li>
              <span>
                Interaktsiooni andmed: teavet individuaalide suhtlemise kohta
                turundusmaterjalidega, nagu e-posti avamis-, klõpsamis-,
                veebisaidi külastus- ja kampaaniatega kaasamismäärad, võib
                töödelda turundusstrateegiate hindamiseks ja optimeerimiseks.
              </span>
            </li>
          </ul>
          <p>
            <span className="text-decoration-underline">
              Andmete töötlemise õiguslik alus:{" "}
            </span>
            <span>
              turundussuhtluse eesmärgil isikuandmete töötlemise õiguslik alus
              võib varieeruda sõltuvalt konkreetsetest olukordadest ja
              kohaldatavatest seadustest. Üldised õiguslikud alused hõlmavad:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Nõusolek: kui isikud on andnud oma selgesõnalise nõusoleku
                turundussuhtluse saamiseks, võib töötlemine põhineda nende
                nõusolekul. Nõusolek peaks olema vabalt antud, konkreetne,
                informeeritud ja tühistatav.
              </span>
            </li>
            <li>
              <span>
                Õigustatud huvid: õigustatud huvid võivad sisaldada otsest
                turundust eelmise kliendi suhte haldamise korral, mis ei ole
                turundussõnumitest välja öelnud.
              </span>
            </li>
            <li>
              <span>
                Andmete saajad: Turundussuhtluse kontekstis võivad isikuandmete
                saajad olla:
              </span>
            </li>
            <li>
              <span>
                Turundusmeeskonnad: isikuandmetele võib juurdepääsu ja neid
                võivad töödelda isikud või meeskonnad, kes vastutavad
                turunduskampaaniate ja -strateegiate planeerimise, täitmise ja
                hindamise eest.
              </span>
            </li>
          </ul>
        </li>
        <li className="mb-3">
          <h5 className="d-inline">Müügiprotsess:</h5>
          <span>
            &nbsp;sealhulgas klientide leidmine, potentsiaalsete klientide
            haldamine, tellimuste täitmine ja kliendisuhete haldus. See hõlmab
            isikuandmete kogumist, analüüsimist ja kasutamist müügitegevuste
            toetamiseks ja klientidele isikupärase kogemuse pakkumiseks
          </span>

          <p>
            <span>
              Müügieesmärgil töödeldavad isikuandmed võivad sisaldada:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Kontaktandmed: see hõlmab nimesid, e-posti aadresse,
                telefoninumbreid, postiaadresse, ametikohti ja muid asjakohaseid
                kontaktandmeid, mis on vajalikud suhtluse alustamiseks ja
                säilitamiseks potentsiaalsete või olemasolevate klientidega.
              </span>
            </li>
            <li>
              <span>
                Tehinguandmed: teavet kliendi tehingute kohta, nagu ostuajalugu,
                tellimuse üksikasjad, makseteave ja arvelduse/saatmisaadresside
                kohta, võib töödelda müügitellimuste haldamiseks ja klienditoe
                pakkumiseks.
              </span>
            </li>
            <li>
              <span>
                Suhtluse ajalugu: andmeid kliendi suhtluse kohta, sealhulgas
                müügipäringuid, toeteateid ja kirjavahetust, võib töödelda
                kliendi kaasatuse jälgimiseks ja isikliku abi osutamiseks.
              </span>
            </li>
            <li>
              <span>
                Müügianalüüs: müügiandmeid, sealhulgas konversioonimäärasid,
                müügitulemusi ja kliendi eelistusi, võib töödelda müügitrendide
                analüüsimiseks, võimaluste tuvastamiseks ja müügistrateegiate
                optimeerimiseks.
              </span>
            </li>
          </ul>
          <p>
            <span className="text-decoration-underline">
              Andmete töötlemise õiguslik alus:
            </span>
            <span>
              &nbsp;müügi puhul isikuandmete töötlemise õiguslik alus võib
              varieeruda sõltuvalt konkreetsetest olukordadest ja
              kohaldatavatest seadustest. Üldised õiguslikud alused hõlmavad:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Lepingu vajadus: kui isikuandmete töötlemine on vajalik lepingu
                täitmiseks või andmesubjekti taotlusel leppetegevuslike sammude
                astumiseks, võib töötlemine põhineda lepingu vajadusele.
              </span>
            </li>
            <li>
              <span>
                Õigustatud huvid: töötlemine võib olla õigustatud selle
                õigusliku aluse alusel. Õigustatud huvid kliendisuhete
                haldamise, mõlema poole vastastikuse kasu ja müügianalüüsi
                tõttu.
              </span>
            </li>
            <li>
              <span>
                Nõusolek: kui isikud on andnud oma selgesõnalise nõusoleku
                töötlemisele müügianalüüsi või sellega seotud suhtluse jaoks,
                võib töötlemine põhineda nende nõusolekul. Nõusolek peaks olema
                vabalt antud, konkreetne, informeeritud ja tühistatav.
              </span>
            </li>
          </ul>
        </li>
        <li className="mb-3">
          <h5 className="d-inline">
            Analüüsi tööriistade kasutamine kasutajate käitumise analüüsimiseks
            ja mõistmiseks, et parandada veebisaidi funktsionaalsust ja pakkuda
            paremat kasutajakogemust.
          </h5>
          <span>
            See hõlmab andmete kogumist ja analüüsimist, et saada ülevaade
            sellest, kuidas külastajad veebisaidiga suhtlevad, platvormid
            optimeerivad sisu ja kujundust ning teevad andmepõhiseid otsuseid.
          </span>

          <p>
            <span>
              Veebilehel surfamise ja analüüsi tööriistade kasutamise ajal
              töödeldavad isikuandmed võivad sisaldada:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Kasutusandmed: see hõlmab teavet selle kohta, kuidas külastajad
                veebisaidiga suhtlevad, nagu külastatud lehed, iga lehel
                veedetud aeg, klõpsumuster, kerimissügavus ja seansi kestus. See
                aitab mõista kasutajate kaasamist ja käitumist.
              </span>
            </li>
            <li>
              <span>
                Seadme ja tehnilised andmed: teavet veebisaidile juurdepääsuks
                kasutatud seadmete kohta, nagu IP-aadressid, seadme tüüp,
                operatsioonisüsteem, brauseri versioon, ekraani resolutsioon ja
                keeleeelistused, võib töödelda tõrkeotsingu, optimeerimise ja
                ühilduvuse tagamiseks.
              </span>
            </li>
            <li>
              <span>
                Viideandmed: andmeid allika kohta, mis suunab kasutaja
                veebisaidile, nagu otsingumootori päringud, suunavad URL-id,
                kampaania parameetrid või sotsiaalmeedia suunamised, võib
                töödelda turundustegevuste hindamiseks ja veebisaidi liikluse
                mõõtmiseks.
              </span>
            </li>
            <li>
              <span>
                Demograafilised andmed: kasutajate asukoha järgi vabatahtlikult
                esitatud valikulisi demograafilisi andmeid võib töödelda, kui
                need kogutakse nõusoleku põhisel viisil, nagu uuringud või
                kasutaja eelistused.
              </span>
            </li>
          </ul>
          <p>
            <span></span>
          </p>
          <p>
            <span className="text-decoration-underline">
              Andmete töötlemise õiguslik alus:{" "}
            </span>
            <span>
              veebilehel surfamise ja analüüsi tööriistade kasutamise ajal
              isikuandmete töötlemise õiguslik alus võib varieeruda sõltuvalt
              konkreetsetest olukordadest ja kohaldatavatest seadustest. Üldised
              õiguslikud alused hõlmavad:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Nõusolek:kohaldatavad andmekaitse-eeskirjad nõuavad nõusolekut;
                kasutajaid palutakse nõusoleku anda enne nende tegevuste
                jälgimist analüüsi tööriistade abil.
              </span>
            </li>
          </ul>
        </li>
        <li className="mb-3">
          <h5 className="d-inline">Demo ja brošüüri taotlused: </h5>
          <span>
            andmeid töödeldakse, et pakkuda taotletud materjale ja suhelda
            taotlejaga taotletud teenuse kohta. Kogutud andmeid kasutatakse
            konkreetse teenuse taotluse täitmiseks ja asjakohase teabe
            pakkumiseks Mercelli teenuste kohta.
          </span>

          <p>
            <span>
              &nbsp;Arvete halduse ja saatmise eesmärgil töödeldavad isikuandmed
              võivad sisaldada:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Kontaktandmed: see hõlmab arve saaja või arve saatmiseks
                määratud saaja nime, aadressi, e-posti aadressi ja
                telefoninumbrit.
              </span>
            </li>
            <li>
              <span>
                Finantsteave: finantstehingutega seotud isikuandmeid, nagu
                pangakonto andmed, maksekaardi andmed või muu maksetega seotud
                teave, võib töödelda arve maksmise ja arvestuse pidamise
                eesmärgil.
              </span>
            </li>
            <li>
              <span>
                Maksutunnuse teave: mõnes jurisdiktsioonis võib töödelda
                maksutunnuseid või muud maksuga seotud teavet kohaldatavate
                maksuseaduste ja -määruste järgimiseks.
              </span>
            </li>
            <li>
              <span>
                Toote või teenuse üksikasjad: teavet pakutavate toodete või
                teenuste kohta, nagu kirjeldus, kogused, hinnad ja kõik
                kohaldatavad maksud või allahindlused, võib töödelda täpse
                arveldamise jaoks.
              </span>
            </li>
          </ul>
          <p>
            <span className="text-decoration-underline">
              Andmete töötlemise õiguslik alus
            </span>
            <span>
              : arvete halduse ja saatmise jaoks isikuandmete töötlemise
              õiguslik alus võib varieeruda sõltuvalt konkreetsetest
              olukordadest ja kohaldatavatest seadustest. Üldised õiguslikud
              alused hõlmavad:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Lepingu vajadus: isikuandmete töötlemine arvete halduse ja
                saatmise jaoks on vajalik lepingu täitmiseks arve saaja ja
                vastutaja vahel.
              </span>
            </li>
            <li>
              <span>
                Õiguslike kohustuste täitmine: isikuandmete töötlemine võib olla
                vajalik õiguslike kohustuste täitmiseks, nagu maksuseadused ja
                -määrused, mis on seotud arveldamise ja arvestuse pidamisega.
              </span>
            </li>
          </ul>
        </li>

        <li className="mb-3">
          <h5 className="d-inline">Arvete haldus</h5>
          <span>
            : tagades täpsuse, hõlbustades makseid ja säilitades korralikku
            raamatupidamist. See hõlmab isikuandmete ja finantsandmete kogumist,
            töötlemist ja salvestamist, mis on vajalikud arveldamise eesmärgil.
          </span>

          <p>
            <span>
              Arvete halduse ja saatmise eesmärgil töödeldavad isikuandmed
              võivad sisaldada:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Kontaktandmed: see hõlmab arve saaja või arve saatmiseks
                määratud saaja nime, aadressi, e-posti aadressi ja
                telefoninumbrit.
              </span>
            </li>
            <li>
              <span>
                Finantsteave: finantstehingutega seotud isikuandmeid, nagu
                pangakonto andmed, maksekaardi andmed või muu maksetega seotud
                teave, võib töödelda arve maksmise ja arvestuse pidamise
                eesmärgil.
              </span>
            </li>
            <li>
              <span>
                Maksutunnuse teave: mõnes jurisdiktsioonis võib töödelda
                maksutunnuseid või muud maksuga seotud teavet kohaldatavate
                maksuseaduste ja -määruste järgimiseks.
              </span>
            </li>
            <li>
              <span>
                Toote või teenuse üksikasjad: teavet pakutavate toodete või
                teenuste kohta, nagu kirjeldus, kogused, hinnad ja kõik
                kohaldatavad maksud või allahindlused, võib töödelda täpse
                arveldamise jaoks.
              </span>
            </li>
          </ul>
          <p>
            <span className="text-decoration-underline">
              Andmete töötlemise õiguslik alus:
            </span>
            <span>
              &nbsp;arvete halduse ja saatmise jaoks isikuandmete töötlemise
              õiguslik alus võib varieeruda sõltuvalt konkreetsetest
              olukordadest ja kohaldatavatest seadustest. Üldised õiguslikud
              alused hõlmavad:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Lepingu vajadus: isikuandmete töötlemine arvete halduse ja
                saatmise jaoks on vajalik lepingu täitmiseks arve saaja ja
                vastutaja vahel.
              </span>
            </li>
            <li>
              <span>
                Õiguslike kohustuste täitmine: isikuandmete töötlemine võib olla
                vajalik õiguslike kohustuste täitmiseks, nagu maksuseadused ja
                -määrused, mis on seotud arveldamise ja arvestuse pidamisega.
              </span>
            </li>
          </ul>
        </li>

        <li className="mb-3">
          <h5 className="d-inline">
            Kliendiandmete salvestamine ja kaitse andmebaasis
          </h5>
          <span>
            : selle eesmärk on säilitada keskne ja organiseeritud teabe hoidla
            klientidega seotud teabe kohta. See hõlmab isikuandmete kogumist,
            korraldamist ja salvestamist, et võimaldada tõhusat kliendihaldust,
            suhtlust ja tuge.
          </span>

          <p>
            <span>
              Kliendiandmete andmebaasis salvestamiseks töödeldavad isikuandmed
              võivad sisaldada:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Identifitseerimisteave: see hõlmab kliendi nime, aadressi,
                riiki, kontaktandmeid (nagu telefoninumber ja e-posti aadress),
                kõiki unikaalseid identifikatoreid (nagu kliendi ID või konto
                number), ametikohta, organisatsiooni nime ja käibemaksunumbrit.
              </span>
            </li>
            <li>
              <span>
                Tehinguajalugu: tehinguandmeid, nagu ostuajalugu, tellimuse
                üksikasjad, makseteave ja arveldamise andmed, võib salvestada
                viitamiseks, analüüsiks ja klienditeeninduse eesmärgil.
              </span>
            </li>
            <li>
              <span>
                Suhtluse ajalugu: kliendi ja organisatsiooni vahelise varasema
                suhtluse kirjed, nagu e-kirjad, vestluse lokid, toeteated või
                kõne salvestused, võib salvestada tervikliku klienditeeninduse
                kogemuse pakkumiseks.
              </span>
            </li>
            <li>
              <span>
                Nõusolek ja eelistused: teavet kliendi nõusoleku kohta
                turundussuhtluse ja eelistuste kohta konkreetsete toodete,
                teenuste või suhtluskanalite osas võib salvestada vastavuse
                tagamiseks ja isikupäraste kogemuste pakkumiseks.
              </span>
            </li>
          </ul>
          <p>
            <span></span>
          </p>
          <p>
            <span className="text-decoration-underline">
              Andmete töötlemise õiguslik alus:{" "}
            </span>
            <span>
              kliendiandmete salvestamiseks ja kaitsmiseks andmebaasis
              isikuandmete töötlemise õiguslik alus võib varieeruda sõltuvalt
              konkreetsetest olukordadest ja kohaldatavatest seadustest. Üldised
              õiguslikud alused hõlmavad:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Lepingu vajadus: kui isikuandmete töötlemine on vajalik lepingu
                täitmiseks või andmesubjekti taotlusel leppetegevuslike sammude
                astumiseks, võib töötlemine põhineda lepingu vajadusele.
              </span>
            </li>
            <li>
              <span>
                Õigustatud huvid: töötlemine võib olla õigustatud selle
                õigusliku aluse alusel kliendisuhete haldamise, mõlema poole
                vastastikuse kasu ja müügianalüüsi tõttu.
              </span>
            </li>
            <li>
              <span>
                Õiguslike kohustuste täitmine: isikuandmete töötlemine võib olla
                vajalik õiguslike kohustuste täitmiseks, nagu maksuseadused ja
                -määrused, mis on seotud arveldamise ja arvestuse pidamisega.
              </span>
            </li>
          </ul>
        </li>

        <li className="mb-3">
          <h5 className="d-inline">E-posti valideerimine:</h5>
          <span>
            &nbsp;e-posti aadresside täpsuse ja kehtivuse kontrollimiseks, mida
            kasutajad on esitanud. Tööriist analüüsib e-posti aadresse, et
            tuvastada võimalikke vigu, ebatäpsusi või petturite aadresse,
            tagades, et ettevõtted saavad säilitada puhta ja usaldusväärse
            e-posti nimekirja tõhusa suhtluse jaoks.
          </span>

          <p>
            <span>
              E-posti valideerimise tööriista kontekstis töödeldavad isikuandmed
              võivad sisaldada:
            </span>
          </p>
          <ul>
            <li>
              <span>
                E-posti aadressid: tööriist töötleb kasutajate poolt esitatud
                e-posti aadresse valideerimise eesmärgil. Seda andmeid
                kasutatakse e-posti aadressi süntaksi, domeeni ja üldse
                kehtivuse kontrollimiseks.
              </span>
            </li>
          </ul>
          <p>
            <span className="text-decoration-underline">
              Andmete töötlemise õiguslik alus:
            </span>
            <span>
              &nbsp;e-posti valideerimise tööriista jaoks isikuandmete
              töötlemise õiguslik alus võib varieeruda sõltuvalt konkreetsetest
              olukordadest ja kohaldatavatest seadustest. Üldised õiguslikud
              alused hõlmavad:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Õigustatud huvid:e-posti aadresside valideerimine aitab tagada
                suhtluse tõhusust, vältida võimalikke probleeme, mis on
                põhjustatud kehtetu või ebatäpse e-posti aadress ja tagada
                tervislik IT-keskkond.
              </span>
            </li>
          </ul>
        </li>

        <li className="mb-3">
          <h5 className="d-inline">Süsteemi hooldus ja tugi:</h5>
          <span>
            &nbsp;need andmetöötlustegevused hõlmavad mis tahes tüüpi
            IT-süsteemide hooldust ja tuge, mis hõlmab regulaarset jälgimist,
            värskendusi ja tõrkeotsingut, et tagada sujuv ja turvaline töö
            süsteemi tellimuste haldamisest.
          </span>
          <p>
            <span>
              Hoolduse ja toe kontekstis töödeldavad isikuandmed võivad
              sisaldada kõiki andmeid, mis on sisalduvad tellimussüsteemi
              halduse punktis:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Kontaktandmed: see hõlmab arve saaja või arve saatmiseks
                määratud saaja nime, aadressi, e-posti aadressi ja
                telefoninumbrit.
              </span>
            </li>
            <li>
              <span>
                Finantsteave: finantstehingutega seotud isikuandmeid, nagu
                pangakonto andmed, maksekaardi andmed või muu maksetega seotud
                teave, võib töödelda arve maksmise ja arvestuse pidamise
                eesmärgil.
              </span>
            </li>
            <li>
              <span>
                Maksutunnuse teave: mõnes jurisdiktsioonis võib töödelda
                maksutunnuseid või muud maksuga seotud teavet kohaldatavate
                maksuseaduste ja -määruste järgimiseks.
              </span>
            </li>
            <li>
              <span>
                Toote või teenuse üksikasjad: teavet pakutavate toodete või
                teenuste kohta, nagu kirjeldus, litsentside kogused, hinnad ja
                kõik kohaldatavad maksud või allahindlused, võib töödelda täpse
                arveldamise jaoks.
              </span>
            </li>
          </ul>
          <p>
            <span className="text-decoration-underline">
              Andmete töötlemise õiguslik alus
            </span>
            <span>:</span>
            <span>
              &nbsp;isikuandmete töötlemise õiguslik alus võib varieeruda
              sõltuvalt konkreetsetest olukordadest ja kohaldatavatest
              seadustest. Üldised õiguslikud alused selleks hõlmavad:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Õigustatud huvi: hoolduse kontekstis on organisatsioonil huvi
                tagada süsteemi terviklikkus ja süsteemi sujuv töö.
              </span>
            </li>
            <li>
              <span>
                Lepingu vajadus: isikuandmete töötlemine, et olla võimeline
                jätkuvalt pakkuma teenust, mis on sisalduvad tellimuse lepingus
                Mercelli poole ja andmete kasutaja vahel, kes omandasid erinevat
                tüüpi tellimusi.
              </span>
            </li>
          </ul>
        </li>

        <li className="mb-3">
          <h5 className="d-inline">Andmetöötlus küsitluste jaoks:</h5>
          <span>
            &nbsp;püüdes pidevalt oma teenuseid parandada ja oma klientide
            vajadusi paremini mõista, võime aeg-ajalt viia läbi küsitlusi ja
            ülevaadete kogumiseks. Küsitluste saatmisel küsime teie nõusolekut
            ja saate alati kas keelduda küsitlusest või pärast esitamist
            taotleda oma õiguse kasutamist vastavates privaatsusmäärustes.
          </span>

          <p>
            <span>
              Küsitluste jaotamise jaoks töödeldavad isikuandmed võivad hõlmata:
            </span>
          </p>
          <ul>
            <li>
              <span>
                Kontaktandmed: see hõlmab osaleja nime, e-posti aadressi ja muid
                kontaktandmeid, mida osaleja on vabatahtlikult küsitlusele
                vastamiseks esitanud.
              </span>
            </li>
            <li>
              <span>
                Demograafiline teave: küsitlusvastuste analüüsimise ja
                suundumuste tuvastamise hõlbustamiseks võidakse koguda
                valikulise demograafilise teabe, näiteks ametikoht, töökoht,
                tegevusala ja asukoht.
              </span>
            </li>
            <li>
              <span>
                Küsitluse vastused: osalejate poolt küsitlusele antud vastused
                võivad sisaldada ka muud tüüpi isikuandmeid, kui nad vastavad
                küsitluses sisalduvatele küsimustele. Andmeid säilitatakse ja
                analüüsitakse, et saada ülevaadet klientide eelistustest,
                rahulolu tasemest ja täiendamise valdkondadest.
              </span>
            </li>
          </ul>
          <p>
            <span>Andmete töötlemise õiguslik alus</span>
            <span>
              : Küsitluste jaotamise jaoks isikuandmete töötlemise õiguslik alus
              põhineb peamiselt nõusolekul. Meie küsitlustes vabatahtlikult
              osaledes ja isikuandmeid esitades annavad osalejad nõusoleku oma
              teabe töötlemiseks nimetatud eesmärkidel. Osalejatel on õigus oma
              nõusolekust igal ajal loobuda ja nende andmed kustutatakse päringu
              kättesaamisel viivitamatult meie registrist.
            </span>
          </p>
          <ul>
            <li>
              <span>
                Säilitustähtaeg: küsitluste jaotamise eesmärgil kogutud
                isikuandmeid säilitatakse ainult 1 aasta. Kui küsitluse analüüs
                on lõpetatud ja neid enam ei vajata, kustutatakse isikuandmed
                turvaliselt või anonüümitakse, et vältida üksikosaliste
                tuvastamist.
              </span>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        <span>
          Kasutajal on igal ajal õigus keelata oma isikuandmete töötlemine,
          välja arvatud juhul, kui see on vajalik lepingust tuleneva nõude
          täitmiseks, sh teenuse osutamiseks.
        </span>
      </p>

      <p>
        <h5>Isikuandmete edastamine kolmandatele isikutele</h5>
      </p>
      <p>
        <span>
          Kolmas isik on TI-haldus OÜ koostööpartner, kes töötleb isikuandmeid
          ja kelle toote või teenuse abil pakub TI-haldus OÜ kasutajale
          teenuseid.
        </span>
      </p>
      <p>
        <span>
          TI-haldus OÜ edastab vaid isikuandmeid, mis on vajalik kasutajale
          teenuse osutamiseks.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <h5>Isikuandmete säilitamine ja turvalisus</h5>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>Turgoil</span>
        <span>
          &nbsp;säilitab isikuandmeid nii kaua, kuni see on eesmärgi täitmiseks
          või seadusest tulenevalt vajalik. Näiteks meililistis olevat e-maili
          aadressi aga seni, kuni kasutaja avaldab soovi see sealt eemaldada.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Turgoil rakendab kõiki mõistlikke meetmeid töödeldavate isikuandmete
          kaitseks. Juurdepääs isikuandmete muutmiseks ja töötlemiseks on ainult
          selleks volitatud isikutel ning kõikide kasutajate isikuandmeid
          käsitletakse kui konfidentsiaalset informatsiooni.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <h5>Kasutaja õigused</h5>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>Kasutajal on õigus igal ajal:</span>
      </p>
      <ul>
        <li>
          <span>avaldada soovi juurdepääsuks enda andmetele;</span>
        </li>
        <li>
          <span>
            nõuda isikuandmete parandamist, täiendamist või seaduses ettenähtud
            tingimustel kustutamist;
          </span>
        </li>
        <li>
          <span>nõuda isikuandmete töötlemise piiramist;</span>
        </li>
        <li>
          <span>nõuda isikuandmete ülekandmist.</span>
        </li>
      </ul>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Oma õiguste kasutamiseks tuleb kasutajal saata e-posti aadressile
          &nbsp;info@turgoil.com vastavasisuline sooviavaldus.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Küpsiste kasutamise piiramiseks saab kasutaja teha vastavad valikud
          oma veebilehitseja seadete alt.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Kui Kasutaja usub, et tema õigusi on isikuandmete töötlemisel rikutud,
          on tal õigus pöörduda Andmekaitse Inspektsiooni poole.
        </span>
      </p>
      <p>
        <h5>Küpsised</h5>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>Turgoil </span>
        <span>
          veebilehel kasutatakse küpsiseid, eesmärgiga tagada parem
          kasutajakogemus ja efektiivsem turundustegevus ning koguda
          kasutusstatistikat.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Kasutades Turgoil veebilehte, nõustub kasutaja küpsiste kasutamisega
          käesolevas korras kirjeldatud viisil.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Küpsis on väike tekstifail, mille veebilehitseja automaatselt kasutaja
          poolt kasutatavasse seadmesse salvestab. Küpsised võivad olla loodud
          erinevate teenusepakkujate poolt, näiteks Google ja Facebook.
          Küpsisefailid ei ole arvuti jaoks ohtlikud.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>Küpsiseid on kahte tüüpi:</span>
      </p>
      <p>
        <span></span>
      </p>
      <ul>
        <li>
          <span>
            Püsivaid küpsiseid, mis jäävad kasutaja arvutis olevasse küpsiste
            faili püsivalt. Neid võidakse kasutada näiteks kasutaja kui
            veebilehe korduvkülastaja äratundmiseks ja veebilehe sisu
            kohandamiseks vastavalt kasutaja vajadustele või statistiliste
            andmete kogumiseks.
          </span>
        </li>
      </ul>
      <p>
        <span></span>
      </p>
      <ul>
        <li>
          <span>
            Seansiküpsised on ajutised ja kaovad, kui kasutaja veebilehelt
            lahkub või oma veebilehitseja sulgeb. Seansiküpsiseid võidakse
            kasutada veebilehe teatud funktsioonide, näiteks teenuse või toote
            taotlemise jms võimaldamiseks.
          </span>
        </li>
      </ul>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Küpsiseid kasutatakse teabe kogumiseks selle kohta, kuidas veebilehte
          kasutatakse, et seda kasutajatele isiklikumaks ja
          kasutajasõbralikumaks muuta. Lisaks aitavad küpsised koguda
          kasutajastatistikat, mis võimaldab mõõta ja parandada veebilehe
          toimivust ning annab alusandmed, et viia läbi efektiivne
          turundustegevus.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Kasutaja loetakse küpsistega nõustunuks, kui tema veebilehitseja
          seadistustes on küpsised lubatud. Tihtipeale veebilehitsejad lubavad
          vaikimisi küpsisefailide säilitamist seadmesse. Kasutaja võib oma
          seadmes küpsiste kasutamise ära keelata või need kustutada, muutes oma
          veebilehitseja vastavaid seadeid. Küpsiste mittekasutamisel ei pruugi
          veebileht korrektselt töötada. Juhiseid veebilehitsejast küpsiste
          kustutamiseks ja seadistamiseks leiab näiteks siit: Chrome, Internet
          Explorer.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Kasutusstatistika kogumiseks kasutab Turgoil Google Analytics teenust,
          kogudes andmeid külastatud alalehtede, külastusaja, külastuste arvu,
          kasutatavate seadmete, sisenemiskanalite ja muu statistika kohta.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Google Analytics võib siiski koguda andmeid kasutajate aktiivsuse
          kohta ka ilma küpsisefaile kasutamata. Selleks, et Google Analytics ei
          saaks andmeid kasutada, saab kasutaja paigaldada oma seadmesse Google
          Analytics’i blokeeriva rakenduse: Google Analyticsist loobumise
          brauserilaiend.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <h5>Privaatsustingimuste muutmine</h5>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>Turgoil</span>
        <span>
          &nbsp;jätab endale õiguse muuta privaatsustingimusi ühepoolselt ja
          ette teatamata, eesmärgiga kindlustada nende vastavus seadustele.
          Privaatsustingimused on oma uusimas versioonis alati veebilehel
          saadaval.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Kõigi privaatsustingimuste ja andmetöötluse kohta tekkivate
          küsimustega palume võtta ühendust aadressil{" "}
        </span>
        <span>
          <a>&nbsp;</a>
        </span>
        <span>
          <a href="mailto:info@turgoil.com">info@turgoil.com</a>
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span></span>
      </p>
    </>
  );
}

type Props = {
  children: React.ReactNode;
};

class InformationPage extends React.PureComponent<Props> {
  static FrequentlyAskedQuestions = FrequentlyAskedQuestions;

  static Instructions = Instructions;

  static Advertisement = Advertisement;

  static TermsAndConditions = TermsAndConditions;

  static PrivacyPolicy = PrivacyPolicy;

  render() {
    return (
      <div className="information-page">
        <div className="container">
          {this.props.children}
          <Footer />
        </div>
      </div>
    );
  }
}

export default InformationPage;
