import React, { PureComponent } from "react";
import { Button, useAccordionButton, Form } from "react-bootstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import { editUserPassword } from "../../../services/turgoil-api";
import * as toastActions from "../../../state/toasts";
import Loader from "../../../common/Loader";
import { ToastType } from "../../../common/Toasts";

function CustomAccordionToggle({ children }) {
  const decoratedOnClick = useAccordionButton("");

  return (
    <Button type="button" variant="light" onClick={decoratedOnClick}>
      {children}
    </Button>
  );
}

type Props = {
  user: any;
  showToast: typeof toastActions.showToast;
};

type State = {
  password: string;
  newPassword: string;
  newPasswordAgain: string;
  showPassword: boolean;
  errorLength: boolean;
  errorNoMatch: boolean;
  errorWrongPassword: boolean;
  loading: boolean;
};

class ChangePassword extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      newPassword: "",
      newPasswordAgain: "",
      showPassword: false,
      errorLength: false,
      errorNoMatch: false,
      errorWrongPassword: false,
      loading: false,
    };
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      [id as "password"]: value,
      errorLength: id === "newPassword" ? false : prevState.errorLength,
      errorNoMatch: id === "newPasswordAgain" ? false : prevState.errorNoMatch,
      errorWrongPassword:
        id === "password" ? false : prevState.errorWrongPassword,
    }));
  };

  handleEyeClick = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { user, showToast } = this.props;

    const { newPassword, password, newPasswordAgain } = this.state;
    const errorLength = newPassword.length < 5;
    if (errorLength) {
      this.setState({ errorLength });
      return;
    }
    const errorNoMatch = newPasswordAgain !== newPassword;
    if (errorNoMatch) {
      this.setState({ errorNoMatch });
      return;
    }
    this.setState({ loading: true });

    editUserPassword(user.id, {
      password,
      newPassword,
    })
      .then(() => {
        this.setState({
          password: "",
          newPassword: "",
          newPasswordAgain: "",
          showPassword: false,
          loading: false,
        });

        showToast({
          title: "Päring õnnestus.",
          text: "Teie parool on edukalt muudetud.",
          type: ToastType.Success,
        });
      })
      .catch((err) => {
        if (err.status === 400) {
          this.setState({
            loading: false,
            password: "",
            errorWrongPassword: true,
          });
        }
      });
  };

  render() {
    const {
      password,
      newPassword,
      newPasswordAgain,
      showPassword,
      errorLength,
      errorWrongPassword,
      errorNoMatch,
      loading,
    } = this.state;
    return (
      <div className="settings__content settings__password">
        {loading && <Loader />}
        {!loading && (
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-2">
                  <Form.Label htmlFor="password">
                    <span>Praegune parool</span>
                  </Form.Label>
                  <Form.Control
                    id="password"
                    onChange={this.handleChange}
                    value={password}
                    type="password"
                    isInvalid={errorWrongPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    Vale parool.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group className="mb-2">
                      <Form.Label htmlFor="newPassword">
                        <span>Uus parool</span>
                      </Form.Label>
                      <div
                        className={classNames("input__container", {
                          "is-invalid": errorLength,
                        })}
                      >
                        <Form.Control
                          id="newPassword"
                          onChange={this.handleChange}
                          value={newPassword}
                          type={showPassword ? "text" : "password"}
                          isInvalid={errorLength}
                        />
                        <img
                          className="input__icon"
                          src={
                            showPassword
                              ? "images/svg/open-eye.svg"
                              : "images/svg/close-eye.svg"
                          }
                          alt="password"
                          onClick={this.handleEyeClick}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Peab sisaldama vähemalt 5 tähemärki.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group className="mb-2">
                      <Form.Label htmlFor="newPasswordAgain">
                        Uus parool uuesti
                      </Form.Label>
                      <div
                        className={classNames("input__container", {
                          "is-invalid": errorNoMatch,
                        })}
                      >
                        <Form.Control
                          id="newPasswordAgain"
                          onChange={this.handleChange}
                          value={newPasswordAgain}
                          type={showPassword ? "text" : "password"}
                          isInvalid={errorNoMatch}
                        />
                        <img
                          className="input__icon"
                          src={
                            showPassword
                              ? "images/svg/open-eye.svg"
                              : "images/svg/close-eye.svg"
                          }
                          alt="password"
                          onClick={this.handleEyeClick}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Paroolid ei ole samad.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <div className="settings__footer">
              <CustomAccordionToggle>Loobu</CustomAccordionToggle>
              <Button type="submit" variant="warning" className="float-end">
                Salvesta
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
