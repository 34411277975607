import { getAuthenticationToken } from "../../utils/user";

function getHeaders() {
  return {
    Accept: "application/json",
    Authorization: `bearer ${getAuthenticationToken()}`,
    "Content-Type": "application/json",
  };
}

function successStatus(res: Response) {
  return res.status && res.status >= 200 && res.status < 400;
}

export type ApiResponse = Response & {
  data: any;
};

export type ApiErrorResponse = Error & {
  status?: string;
  type: string;
};

/**
 * @param {Object} res
 */
async function tryToParseResponse(res: Response): Promise<ApiResponse> {
  let data: any;
  try {
    data = await res.json();
  } catch (e) {
    data = {};
  }

  (res as any).data = data;
  return res as unknown as Promise<ApiResponse>;
}

export async function post(
  path: string,
  body: any,
  options = {},
): Promise<ApiResponse> {
  const reqOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(body),
    ...options,
  };

  const res = await fetch(
    `${process.env.REACT_APP_PUBLIC_API}${path}`,
    reqOptions,
  );
  const apiRes = await tryToParseResponse(res);
  if (!successStatus(apiRes)) {
    throw apiRes;
  }

  return apiRes;
}

export async function get(path: string, options = {}): Promise<ApiResponse> {
  const reqOptions = {
    method: "GET",
    headers: getHeaders(),
    ...options,
  };

  const res = await fetch(
    `${process.env.REACT_APP_PUBLIC_API}${path}`,
    reqOptions,
  );
  const apiRes = await tryToParseResponse(res);
  if (!successStatus(apiRes)) {
    throw apiRes;
  }

  return apiRes;
}

export async function put(
  path: string,
  body: any,
  options = {},
): Promise<ApiResponse> {
  const reqOptions = {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(body),
    ...options,
  };

  const res = await fetch(
    `${process.env.REACT_APP_PUBLIC_API}${path}`,
    reqOptions,
  );
  const apiRes = await tryToParseResponse(res);
  if (!successStatus(apiRes)) {
    throw apiRes;
  }

  return apiRes;
}
