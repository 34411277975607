import React, { Component } from "react";
import { Helmet } from "react-helmet";

import { PictureLayoutForm } from "../../common/Layout";
import Loader from "../../common/Loader";
import SignUpForm from "./sub/SignUpForm";
import SignUpComplete from "./sub/SignUpComplete";
import { Link } from "react-router-dom";

const Steps = Object.freeze({
  StartRegistration: "StartRegistration",
  CompleteRegistration: "CompleteRegistration",
});

class SignUpPage extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      step: Steps.StartRegistration,
      loading: false,
    };
  }

  nextStep = () => {
    switch (this.state.step) {
      case Steps.StartRegistration:
        this.setState({ step: Steps.CompleteRegistration });
        break;
      default:
        this.setState({ step: Steps.StartRegistration });
    }
  };

  step = () => {
    const { step } = this.state;
    switch (step) {
      case Steps.CompleteRegistration:
        return <SignUpComplete />;
      default:
        return <SignUpForm nextStep={this.nextStep} />;
    }
  };

  render() {
    return (
      <PictureLayoutForm>
        <Helmet>
          <title>Turgoil - Kasutaja loomine</title>
          <meta
            name="description"
            content="Logi sisse või loo kasutaja, et korraldada vedelkütuse hange juba täna."
          />
        </Helmet>
        <h2>Kasutaja loomine</h2>
        <p>
          <span>Kas sul on juba kasutaja? </span>
          <Link to="/signin">
            <a className="login-page__link" href="/signin">
              Logi sisse
            </a>
          </Link>
        </p>
        {!this.state.loading && this.step()}
        {this.state.loading && <Loader />}
      </PictureLayoutForm>
    );
  }
}

export default SignUpPage;
