import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "../../../common/Select";
import { OilRequestOilType } from "../../../utils/config";
import {
  oilAdditiveOptions,
  oilMarkingOptions,
  oilPurposeOptions,
} from "../helpers";

export type Oil = {
  oilCode: string;
  amount: string;
  // hasBioAdditive?: boolean;
  // hvo?: string;
  marking?: string;
  purpose?: string;
};

type Props = {
  title: string;
  oilCode: string;
  onChange: (oil: Oil) => void;
  error?: Partial<Record<keyof Oil, string>>;
};

const OilForm: React.FC<Props> = ({
  oilCode,
  title,
  error,
  onChange,
}): React.ReactElement => {
  const [oil, setOil] = useState<Oil>({ oilCode, amount: "" });

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOil({
      ...oil,
      [name]: value,
    });
  };

  const handleSelectChange = (
    o: { value: any } | null,
    m: { name?: string },
  ) => {
    const { value } = o || {};
    const { name } = m;
    setOil({
      ...oil,
      [name]: value,
    });
  };

  useEffect(() => {
    onChange(oil);
  }, [oil, onChange]);

  return (
    <>
      <div className="col-sm-3">
        <h6>{title}</h6>
        <div>
          <Form.Label>Kogus</Form.Label>
          <InputGroup className="mb-2">
            <Form.Control
              type="number"
              onChange={handleTextChange}
              name="amount"
              isInvalid={!!error?.amount}
            />
            <InputGroup.Text>L</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {error?.amount}
            </Form.Control.Feedback>
          </InputGroup>
        </div>
        {[String(OilRequestOilType.DieselSpecial)].includes(oilCode) && (
          <div>
            <Form.Label>Otstarve</Form.Label>
            <Form.Group className="mb-2">
              <Select
                isClearable
                onChange={handleSelectChange}
                name="purpose"
                placeholder=""
                options={oilPurposeOptions}
                isInvalid={!!error?.purpose}
              />
              <Form.Control.Feedback type="invalid">
                {error?.purpose}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        )}
        {[
          String(OilRequestOilType.DieselSpecial),
          String(OilRequestOilType.Diesel),
        ].includes(oilCode) && (
          <div>
            <Form.Label>Märgistus</Form.Label>
            <Form.Group className="mb-2">
              <Select
                isClearable
                onChange={handleSelectChange}
                name="marking"
                placeholder=""
                options={oilMarkingOptions}
                isInvalid={!!error?.marking}
              />
              <Form.Control.Feedback type="invalid">
                {error?.marking}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        )}
        {/* {[String(OilRequestOilType.Petrol95), String(OilRequestOilType.Petrol98)].includes(oilCode) && ( */}
        {/*   <div> */}
        {/*     <Form.Label> */}
        {/*       HVO */}
        {/*     </Form.Label> */}
        {/*     <Form.Group className="mb-2"> */}
        {/*       <Select */}
        {/*         isClearable */}
        {/*         onChange={handleSelectChange} */}
        {/*         placeholder="" */}
        {/*         name="marking" */}
        {/*         options={oilMarkingOptions} */}
        {/*         isInvalid={!!error?.marking} */}
        {/*       /> */}
        {/*       <Form.Control.Feedback type="invalid"> */}
        {/*         {error?.marking} */}
        {/*       </Form.Control.Feedback> */}
        {/*     </Form.Group> */}
        {/*   </div> */}
        {/* )} */}
        {/* {[String(OilRequestOilType.Petrol95), String(OilRequestOilType.Petrol98)].includes(oilCode) && ( */}
        {/*   <div> */}
        {/*     <Form.Label> */}
        {/*       Täiendav info */}
        {/*     </Form.Label> */}
        {/*     <Form.Group className="mb-2"> */}
        {/*       <Select */}
        {/*         isClearable */}
        {/*         onChange={handleSelectChange} */}
        {/*         placeholder="" */}
        {/*         name="marking" */}
        {/*         options={oilMarkingOptions} */}
        {/*         isInvalid={!!error?.marking} */}
        {/*       /> */}
        {/*       <Form.Control.Feedback type="invalid"> */}
        {/*         {error?.marking} */}
        {/*       </Form.Control.Feedback> */}
        {/*     </Form.Group> */}
        {/*   </div> */}
        {/* )} */}
        {/* {[String(OilRequestOilType.Diesel), String(OilRequestOilType.DieselSpecial)].includes(oilCode) && ( */}
        {/*   <div> */}
        {/*     <Form.Label> */}
        {/*       Täiendav info */}
        {/*     </Form.Label> */}
        {/*     <Form.Group className="mb-2"> */}
        {/*       <Select */}
        {/*         isClearable */}
        {/*         onChange={handleSelectChange} */}
        {/*         name="hasBioAdditive" */}
        {/*         placeholder="" */}
        {/*         options={oilAdditiveOptions} */}
        {/*         isInvalid={!!error?.hasBioAdditive} */}
        {/*       /> */}
        {/*       <Form.Control.Feedback type="invalid"> */}
        {/*         {error?.hasBioAdditive} */}
        {/*       </Form.Control.Feedback> */}
        {/*     </Form.Group> */}
        {/*   </div> */}
        {/* )} */}
      </div>
    </>
  );
};

export default OilForm;
