import React from "react";
import { Link } from "react-router-dom";

export default function LandingSlogan() {
  return (
    <div className="landing-slogan">
      <h2 className="landing-slogan__title-primary">
        Hulgi vedelkütuse hanked
      </h2>
      <h5 className="landing-slogan__title-secondary mb-4">
        Turgoil.com on Eesti esimene vedelkütuse hankeid võimaldav keskkond.
        Ettevõte tegeleb hulgi vedelkütuse hangete vahendamisega. Kui soovid
        osta vedelkütustust, milleks on bensiin 95, bensiin 98, diislikütus või
        erimärgistusega diislikütus, siis
        <b> Loo kasutaja ning korralda vedelkütuse hange Tasuta!</b>
      </h5>
      <div className="landing-slogan__links">
        <Link
          to="/oil-requests/create"
          className="btn btn-lg btn-outline-warning"
        >
          KORRALDA HANGE
        </Link>
      </div>
    </div>
  );
}
