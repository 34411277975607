import React, { PureComponent } from "react";
import {
  Popover,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Big from "big.js";

import * as date from "../../../utils/date";
import { numberWithSpaces } from "../../../utils/format";
import { UserRole } from "../../../utils/config";
import { getDisplayValueWithTax } from "./CreateOrEditBid";
import { getNameForOil } from "../../../common/Oils";

/**
 * Requester can see all the information only if request is in
 * the following states.
 */
const RequestStates = ["Decision", "Active", "Closed", "Transport"];

type Props = {
  requesterId: number;
  requestStateCode: string;
  bid: any;
  user: any;
  onOilRequestWinner: (e: any, b: any) => void;
  oilRequestMarkup?: string;
  oilRequestOils: { oilType: string; id: number }[];
};

type State = {
  markup: string;
  markupError: string;
};

class Bid extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      markup: "",
      markupError: "",
    };
  }

  hidePopover = () => {
    document.body.click();
  };

  handleOilRequestWinner = (e) => {
    const { onOilRequestWinner } = this.props;
    const { markup } = this.state;
    const bidId = e.target.getAttribute("data-value");
    if (markup && new Big(markup || 0).lt(0)) {
      this.setState({
        markupError: "Juurdehindlus peab olema positiivne väärtus",
      });
      return;
    }

    onOilRequestWinner(bidId, markup);
  };

  handleMarkupChange = (e) => {
    e.persist();

    this.setState({
      markup: e.target.value,
      markupError: "",
    });
  };

  private getBidTooltip = (
    bids: [{ price: number; oilId: number }],
  ): React.ReactNode => {
    const { oilRequestOils } = this.props;
    return (bids || []).map((bid) => {
      const oilType = oilRequestOils.find((o) => o.id === bid.oilId).oilType;
      return (
        <>
          {getNameForOil(oilType)}: {bid.price}
          <br />
        </>
      );
    });
  };

  render() {
    const { bid, user, requesterId, requestStateCode } = this.props;
    const { oilRequestMarkup, oilRequestOils } = this.props;

    const REQUESTER =
      RequestStates.includes(requestStateCode) && requesterId === user.id;
    const REQUEST_STATUS_DECISION = requestStateCode === "Decision";

    const confirmPopover = (
      <Popover id="popover-basic">
        <Popover.Header as="h3">
          Kas olete kindel, et hanke võitjaks kuulutatakse{" "}
          <span className="configrm__emphasize">{bid.companyName}</span>?
        </Popover.Header>
        <Popover.Body>
          <p>
            Pärast võitja valimist, läheb hange olekusse "Teostamine". Ning
            Teile saadetakse võitja kontaktandmed.
          </p>

          {[UserRole.Agent].includes(user.role) && (
            <Form.Group className="mb-2">
              <Form.Label className="mb-0 d-block">
                Juurdehindlus eurodes
              </Form.Label>
              <Form.Text muted>
                Täitke juhul kui olete vahendaja ning soovite, et arvel
                kajastuks suurem summa.
              </Form.Text>
              <Form.Control
                type="number"
                name="markup"
                placeholder=""
                onChange={this.handleMarkupChange}
                isInvalid={Boolean(this.state.markupError)}
                value={this.state.markup}
                step="1"
                min="0"
              />

              <Form.Control.Feedback type="invalid">
                {this.state.markupError}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          {this.state.markup && (
            <>
              <Form.Group className="mb-2">
                <Form.Label className="mb-0 d-block">Summa</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  plaintext
                  readOnly
                  value={
                    numberWithSpaces(
                      new Big(bid.price || 0)
                        .plus(this.state.markup || 0)
                        .toFixed(2),
                    ) + " €"
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label className="mb-0 d-block">
                  Summa käibemaksuga
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  plaintext
                  readOnly
                  value={
                    numberWithSpaces(
                      getDisplayValueWithTax(
                        new Big(bid.price || 0).plus(this.state.markup || 0),
                        2,
                      ),
                    ) + " €"
                  }
                />
              </Form.Group>
            </>
          )}

          <hr />

          <Button type="button" variant="light" onClick={this.hidePopover}>
            Loobu
          </Button>
          <Button
            type="button"
            variant="warning"
            className="float-end"
            onClick={this.handleOilRequestWinner}
            data-value={bid.id}
          >
            Jah
          </Button>
        </Popover.Body>
      </Popover>
    );

    const companyName = bid.companyName ? `${bid.companyName}` : "Varjatud";
    const price = bid.price ? (
      bid.oilBids ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{this.getBidTooltip(bid.oilBids)}</Tooltip>}
        >
          <span>{numberWithSpaces(new Big(bid.price || 0).toFixed(2))} €</span>
        </OverlayTrigger>
      ) : (
        <span>{numberWithSpaces(new Big(bid.price || 0).toFixed(2))} €</span>
      )
    ) : (
      "Varjatud"
    );
    const priceWithTax = bid.price
      ? `${numberWithSpaces(getDisplayValueWithTax(new Big(bid.price || 0).toFixed(2), 2))} €`
      : "Varjatud";
    const comment = (
      <div>
        {bid.comment ? bid.comment : "–"}
        <div>
          {bid.oilBids?.map((b) => {
            const oil = oilRequestOils.find((oro) => oro.id === b.oilId);
            if (b.marking || b.cloudPoint || b.coldFilterPlugPoint) {
              const r = [];
              r.push(`${getNameForOil(oil.oilType)}`);
              if (b.marking) {
                r.push(`Märgistus ${b.marking}`);
              }
              if (b.cloudPoint) {
                r.push(`CP ${b.cloudPoint}`);
              }
              if (b.coldFilterPlugPoint) {
                r.push(`CFPP ${b.coldFilterPlugPoint}`);
              }
              return <div>{r.join(", ")}</div>;
            }
            return undefined;
          })}
        </div>
      </div>
    );
    const markup = `${numberWithSpaces(new Big(oilRequestMarkup || 0).toFixed(2))} €`;
    const markupTotal = `${numberWithSpaces(new Big(oilRequestMarkup || 0).plus(new Big(bid.price || 0)).toFixed(2))} €`;
    const markupTotalWithTax = `${numberWithSpaces(getDisplayValueWithTax(new Big(oilRequestMarkup || 0).plus(new Big(bid.price || 0)), 2))} €`;
    const modificationDate = date.getDateTime(bid.modDatetime);
    const tools = REQUESTER && REQUEST_STATUS_DECISION && (
      <>
        <OverlayTrigger rootClose trigger="click" placement="top" overlay={confirmPopover}>
          <Button
            type="button"
            variant="warning"
            className="text-nowrap"
          >
            Kuuluta võitjaks
          </Button>
        </OverlayTrigger>
      </>
    );

    return (
      <>
        <tr className="d-none d-md-table-row">
          <td>{companyName}</td>
          <td>{price}</td>
          {!oilRequestMarkup && <td>{priceWithTax}</td>}
          <td>{comment}</td>
          {oilRequestMarkup && <td>{markup}</td>}
          {oilRequestMarkup && <td>{markupTotal}</td>}
          {oilRequestMarkup && <td>{markupTotalWithTax}</td>}
          <td>{modificationDate}</td>
          <td>{tools}</td>
        </tr>

        <div className="d-block d-md-none">
          <div className="oil-request-page__title">Pakkuja:</div>
          <div>{companyName}</div>
          <div>{tools}</div>
          <div className="oil-request-page__title">Pakkumine:</div>
          <div>{price}</div>
          {!oilRequestMarkup && (
            <>
              <div className="oil-request-page__title">
                Pakkumine käibemaksuga:
              </div>
              <div>{priceWithTax}</div>
            </>
          )}
          <div className="oil-request-page__title">Kommentaar:</div>
          <div>{comment}</div>
          {oilRequestMarkup && (
            <>
              <div className="oil-request-page__title">Juurdehindlus:</div>
              <div>{markup}</div>
            </>
          )}
          {oilRequestMarkup && (
            <>
              <div className="oil-request-page__title">Summa:</div>
              <div>{markupTotal}</div>
            </>
          )}
          <div></div>
          {oilRequestMarkup && (
            <>
              <div className="oil-request-page__title">Summa käibemaksuga:</div>
              <div>{markupTotalWithTax}</div>
            </>
          )}
          <div className="oil-request-page__title">Lisatud:</div>
          <div>{modificationDate}</div>
        </div>
      </>
    );
  }
}

export default Bid;
