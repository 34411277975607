/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import H from "history";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export interface RouteComponentProps<P = unknown, S = unknown> {
  location: H.Location & {
    state: S;
  };
  params: P;
  navigate: ReturnType<typeof useNavigate>;
}

export const withRouter =
  <Props extends RouteComponentProps>(Component: React.ComponentType<Props>) =>
  (props: Omit<Props, keyof RouteComponentProps>) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    return (
      <Component
        {...(props as Props)}
        location={location}
        params={params}
        navigate={navigate}
      />
    );
  };
